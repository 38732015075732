/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import dateformat from "dateformat";

function More(props) {
    const planning = props.planning
    return (
        <div className="row">
            <div className="col-12 more-info-box">
                <div className="header-widget">
                    <div className="title">
                        Thông tin thêm
                    </div>
                </div>
                <div className="content-result planning-result-more-info">
                    <div className="more-title">Email liên hệ:</div>
                    banbientap@mpi.gov.vn
                    <hr />
                    <div className="more-title">Danh mục:</div>
                    {
                        planning && (
                            <a>{planning.categoryName}</a>
                        )
                    }
                    <hr />
                    <div className="more-title">Từ khóa:</div>
                    {

                    }
                    <hr />
                    <div className="more-title">Ngày xuất bản:</div>
                    {
                        planning && (
                            <a>{dateformat(planning.publishDate, "dd/mm/yyyy")}</a>
                        )
                    }
                    <hr />
                    <div className="more-title">Tình trạng:</div>
                    {
                        planning && (
                            <a>{planning.projectStatusName}</a>
                        )
                    }
                    <hr />
                    <div className="more-title">Thời gian hiệu lực:</div>
                    {
                        (planning && planning.expiredDate) ? dateformat(planning.publishDate, "dd/mm/yyyy")
                            : "không xác định"
                    }

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(More));
