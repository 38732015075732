import { Dialog } from "@material-ui/core";
import React, { Component } from "react";
import "./lightbox.scss";
function LightboxVideo(props) {
  const { open, onClose, detail } = props;
  return (
    <div className="container-light-box">
      <div className="icon-close" onClick={()=>onClose()}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="50" height="50" rx="4" fill="black" />
          <path
            d="M33.696 16.3047L16.3047 33.696"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.3047 16.3047L33.696 33.696"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <Dialog aria-labelledby="simple-dialog-title" maxWidth="lg" open={open}>
        <iframe
          className="responsive-iframe"
          src={detail.linkYoutubeEmbed}
          height={600}
          width={900}
        />
      </Dialog>
    </div>
  );
}

export default LightboxVideo;
