/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ViLanguage from "../../languages/vi";
import * as statementActions from "../../redux/store/statement/statement.store";
import * as appActions from "../../core/app.store";
import * as acountAction from "../../redux/store/account/account.store";
import "./top.scss";
import {Navbar} from "react-bootstrap";

const viLanguage = ViLanguage;


class TopView extends React.Component {
    constructor(props) {
        super(props);
        //this.getUserAccountDetail();
    }

    getUserAccountDetail() {
        acountAction.GetUserAccountDetail().then((res) => {
            if(res && res.content && res.content.id){
                //console.log('user account detail',res.content);
            }else{
                window.location = '/dang-nhap';
            }
        }).catch((err) => {
            window.location = '/dang-nhap';
        });
    }
    render() {
        return (
            <div className="position-absolute"></div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            getAllPlanningType: statementActions.PlanningTypeDispatch,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TopView);
