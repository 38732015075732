/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./comment.scss";
import * as appActions from "../../core/app.store";
import * as commentActions from "../../redux/store/comment/comment.store";

function ShowCommentItems(props) {
    const { items, index, onLikeClick, onReplyClick, onKeyDown } = props;
    const [replyContent, setReplyContent] = useState("");
    const replyInput = useRef(null);
    const onKeyDownPress = (e, id) => {
        if (e.key === 'Enter') {
            onKeyDown(e, id);
            setReplyContent("");
        }
    }
    const onReply = (id) => {
        replyInput.current.focus();
    }
    return (
        <div className="row" key={index}>
            <div className="col-12">
                <div className="comment-entry">
                    <b>{items.personName} &nbsp;</b>
                    {items.content}
                </div>
                <div className="comment-entry-count">
                    <span>{items.likeCount}</span>
                    &nbsp;<span>
                    <a style={{ "cursor": "pointer", "position":"relative", "top":"-2px"}} onClick={() => onLikeClick(items.id)}>
                        <svg width={14} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="thumbs-up"
                             className="svg-inline--fa fa-thumbs-up fa-w-16" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="#4e555b" d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"></path></svg>
                    </a></span>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>
                        <a style={{ "cursor": "pointer" }} onClick={() => onReply(items.id)}>trả lời</a></span>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="time-answer">{items.howLong}</span>&nbsp;
                </div>

                <div className="comment-replay">
                    {
                        items.replies && items.replies.length > 0 && (
                            <ShowListComment listItems={items.replies} onLikeClick={onLikeClick} />
                        )
                    }
                    {
                        items.parentId ? "" : <div>
                            <input
                                ref={replyInput}
                                placeholder="Nhập trả lời..."
                                name="replies"
                                fullWidth
                                type="text"
                                value={replyContent}
                                onChange={(e) => setReplyContent(e.target.value)}
                                className="form-control"
                                inputProps={{ maxLength: 150 }}
                                onKeyDown={(e) => { onKeyDownPress(e, items.id) }}
                            />
                        </div>
                    }
                </div>
            </div>
            {
                items.parentId && <hr />
            }
        </div>
        )
}

function ShowListComment(props) {
    const { listItems, onLikeClick, onReplyClick, onKeyDown } = props;
    const [listModel, setListModel] = useState([]);
    useEffect(() => {
        setListModel(listItems);
    }, [listItems])
    return (
        <div className="row comment-list">
            <div className="col-12">
                {
                    listModel && listModel.length > 0 && (
                        listModel.map((items, index) =>
                            <ShowCommentItems
                                items={items}
                                index={index}
                                onLikeClick={onLikeClick}
                                onReplyClick={onReplyClick}
                                onKeyDown={onKeyDown}
                            />
                        )
                    )
                }
            </div>
        </div>
        );
}

function CommentView(props) {
    const { showLoading, referrenceId, referrenceType } = props;

    const [pageCount, setPageCount] = useState();
    const [listComment, setListComment] = useState([]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const pageSize = 10;
    const [dataSearch, setDataSearch] = useState({ pageIndex: 1, pageSize: pageSize, referrenceId: referrenceId, referrenceType: referrenceType });
    const [isLoading, setIsLoadding] = useState(false);
    const [content, setContent] = useState();
    const [replyContent, setReplyContent] = useState("");

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        setIsLoadding(true);
        Promise.all([
            onGetListComment(dataSearch),
        ])
            .then((res) => {
                setIsLoadding(false);
            })
            .catch((err) => {
                setIsLoadding(false);
            });
    };

    const onGetListComment = (data) => {
        return new Promise((resolve, reject) => {
            commentActions.GetListAllNoPage(data).then(
                (res) => {
                    setListComment(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    //setTotalItemCount(
                    //    res && res.content && res.content.totalItemCount
                    //        ? res.content.totalItemCount
                    //        : 0
                    //);
                    //setPageCount(
                    //    res && res.content && res.content.pageCount
                    //        ? res.content.pageCount
                    //        : 0
                    //);
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onClickReply = (id) => {

    }

    const onClickLike = (id) => {
        commentActions.Like(id).then(
            (res) => {
                if (res.content.status) {
                    //let listTmp = listComment;
                    //let item = listComment.find(x => x.id == id);
                    //if (item) {
                    //    item.likeCount = res.content.status;
                    //    let index = listTmp.indexOf(item);
                    //    if (index != -1)
                    //        listTmp.splice(index, 1);
                    //}
                    //setListComment(listTmp);
                    onGetListComment(dataSearch);
                }
                setIsLoadding(false);
            },
            (err) => {
                setIsLoadding(false);
            }
        );
    }

    const onKeyDown = (e, id) => {
        if (e.key === 'Enter') {
            if (e.target.value == "") return;
            let data = {};
            data.referrenceType = referrenceType;
            data.content = e.target.value;
            data.parentId = id;
            data.personName = "Vũ";
            setIsLoadding(true);
            commentActions.Create(data).then(
                (res) => {
                    if (res.content.status) {
                        onGetListComment(dataSearch);
                    }
                    setIsLoadding(false);
                },
                (err) => {
                    setIsLoadding(false);
                }
            );
        }
    }

    const sendClick = () => {
        let data = {};
        data.referrenceType = referrenceType;
        data.referrenceId = referrenceId;
        data.content = content;
        data.personName = "Vũ";
        setIsLoadding(true);
        commentActions.Create(data).then(
            (res) => {
                if (res.content.status) {
                    onGetListComment(dataSearch);
                    setContent("");
                }
                setIsLoadding(false);
            },
            (err) => {
                setIsLoadding(false);
            }
        );
    }
 
    return (
    <div className="row comment-box">
            <div className="col-12">
                <div className="row">
                    <div className="col-12 comment-title">
                        Bình luận (
                        {
                            listComment && (<span>{listComment.length}</span>)
                        }
                        )
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12">
                        <div className="comment-content">
                            <textarea
                                name="content"
                                placeholder="Viết bình luận..."
                                onChange={(e) => { setContent(e.target.value) }}
                                value={content}
                                rows="3"
                                maxLength="500"
                                className="form-control"
                            ></textarea>
                        </div>
                        <div className="comment-button-box">
                            <button id="Gui" name="Gui" onClick={ ()=>sendClick()}>Gửi</button>
                        </div>
                    </div>
                </div>
                <ShowListComment
                    listItems={listComment}
                    onLikeClick={onClickLike}
                    onReplyClick={onClickReply}
                    onKeyDown={onKeyDown} />
                {
                    isLoading && (
                        <div id="loadingblock" className="loading-app">
                            <div className="canvas">
                                <div className="spinner"></div>
                            </div>
                        </div>
                    )
                }
            </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  listPolygon: state.mapData.listPolygon,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
          showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(CommentView));
