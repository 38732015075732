/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, memo } from "react";
import DatePicker from "react-datepicker";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "react-datepicker/dist/react-datepicker.css";
import AddDocuments from "../add-documents/AddDocuments.jsx";
import AddText from "../add-text/AddText.jsx";
import { uuid } from 'uuidv4';
const useStyles = makeStyles({
  uploadFile: {
    marginTop: 24,

    "& label": {
      marginBottom: 0,
    },
  },
  uploadFileLabel: {
    fontWeight: 500,
    lineHeight: "19px",
    color: "#383838",
    marginBottom: "10px !important",
  },
  inputLabel: {
    width: 350,
    padding: "14px 15px",
    backgroundColor: "#E0ECF5",
    color: "#383838",
  },
  inputLabelMeta: {
    width: 350,
    padding: "14px 15px",
    color: "#383838",
    borderRight: "1px solid #A5A5A5",
  },
  uploadFileInput: {
    height: 50,
    border: "1px solid #A5A5A5",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  fileName: {
    flex: 1,
    padding: "14px",
    border: "1px solid #A5A5A5",
    borderTop: 0,
    borderBottom: 0,
    height: 50,
  },
  uploadButton: {
    padding: "6px 14px",
    color: "#fff",
    backgroundColor: "#359AE1",
    borderRadius: "3px",
    margin: "0 10px",
    fontSize: 15,
    height: 32,

    "&:hover": {
      cursor: "pointer",
    },
  },
  removeButton: {
    padding: "6px 14px",
    color: "#fff",
    backgroundColor: "red",
    borderRadius: "3px",
    margin: "0 10px",
    fontSize: 15,
    height: 32,

    "&:hover": {
      cursor: "pointer",
    },
  },
  updateButton: {
    padding: "6px 14px",
    color: "#fff",
    backgroundColor: "#359AE1",
    borderRadius: "3px",
    margin: "0 10px",
    fontSize: 15,
    height: 32,

    "&:hover": {
      cursor: "pointer",
    },
  },
  inputMeta: {
    width: "calc(100% - 351px)",
    height: "100%",
    border: 0,
  },
  updateMetadata: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  addFileInput: {
    height: 50,
    border: "1px solid #A5A5A5",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "center",
  },
  addInputLabel: {
    width: 350,
    padding: "14px 15px",
    color: "#383838",
    border: "0px",
  },
  goBack: {
    marginTop: 15,
    color: "#8b8787",
    cursor: "pointer",
  },
});

const useStylesProgress = makeStyles({
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(-90deg) !important",
  },
});

function CircularProgressWithLabel(props) {
  const classes = useStylesProgress();
  return (
    <Box position="relative" display="inline-flex" minWidth={100}>
      <CircularProgress
        variant="determinate"
        className={classes.circularProgress}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
function uuidTmp() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
const UploadFileComponent = ({
  index,
  item,
  listFile = {},
  onChange,
  onUpdate,
  onRemove,
  register,
  errors,
  clearErrors,
  recordsGovernment,
  showButton = true,
  requireMetadata,
  optionAddRow,
  setOptionAddRow,
  addRowFile,
  setAddRowFile,
  handleChangeMeta,
  handleChangeFileAddRow,
  onRemoveAddRowFile,
  reomveFileAddRow,
  onAddorUpdate,
  componentDocument,
  setComponentDocument,
  componentText,
  setComponentText,
}) => {
  const classes = useStyles();
  

  const onAddComponentDocument = (id) => {
    const idItem = uuidTmp();
    const tmp1= [idItem];
    const tmp3 = componentDocument[id] || [];
    const tmp2= tmp3.concat(tmp1)

    setComponentDocument({
      ...componentDocument,
      [item.id]: tmp2
    })
  };
  const onAddComponentText = (id) => {
    const idItem = uuidTmp();
    const tmp1= [idItem];
    const tmp3 = componentText[id] || [];
    const tmp2= tmp3.concat(tmp1)

    setComponentText({
      ...componentText,
      [item.id]: tmp2
    })
  };

  return (
    <div className={classes.uploadFile}>
      <label className={classes.uploadFileLabel}>
        {`${index}. ${item?.label}`}
        {item?.required && <label className="required" />}
      </label>
      {item?.field?.map((element, elementIndex) => (
        <Fragment key={elementIndex}>
          <div className={classes.uploadFileInput}>
            <label className={classes.inputLabel}>{element?.label}</label>
            <input
              id={element?.name}
              type="file"
              name={element?.name}
              className="media-file"
              ref={register({
                required: item?.required && !listFile[element?.name]?.name,
              })}
              onClick={() => {
                clearErrors([`${element?.name}`]);
              }}
              onChange={(e) => onChange(e, element?.isRequireMetadata, 0)}
              hidden
            />
            {listFile[element?.name]?.name ? (
              <a
                href={`${listFile[element?.name]?.preview || ""}`}
                className={classes.fileName}
                target="_blank"
                rel="noopener noreferrer"
              >
                {listFile[element?.name]?.name}
              </a>
            ) : (
              <a className={classes.fileName} rel="noopener noreferrer"></a>
            )}

            {!listFile[element?.name]?.uploadProgress &&
              showButton &&
              !listFile[element?.name]?.name && (
                <label htmlFor={element?.name} className={classes.uploadButton}>
                  Chọn file
                </label>
              )}
            {!listFile[element?.name]?.uploadProgress &&
              !element?.isRequireMetadata &&
              showButton &&
              listFile[element?.name]?.name && (
                <label
                  className={classes.removeButton}
                  onClick={() => onRemove(element?.name, listFile[element?.name]?.idRecordsFile)}
                >
                  Xóa file
                </label>
              )}
            {!!listFile[element?.name]?.uploadProgress && (
              <CircularProgressWithLabel
                value={listFile[element?.name]?.uploadProgress}
              />
            )}
          </div>
          {errors[`${element?.name}`] &&
            errors[`${element?.name}`].type === "required" && (
              <span className="error">Trường này là bắt buộc</span>
            )}
          {element?.isRequireMetadata && (
            <div>
              <div className={classes.uploadFileInput}>
                <label className={classes.inputLabelMeta}>
                  Số hiệu văn bản
                </label>
                <input
                  type="text"
                  name="recordsFileNo"
                  disabled={!showButton || false}
                  className={classes.inputMeta}
                  value={requireMetadata && requireMetadata[element?.name]?.recordsFileNo ? requireMetadata[element?.name]?.recordsFileNo : ''}
                  onChange={(e) => handleChangeMeta(e, element?.name)}
                />
              </div>
              <div className={classes.uploadFileInput}>
                <label className={classes.inputLabelMeta}>Ngày ban hành </label>
                <DatePicker
                  name="recordsFileDate"
                  selected={requireMetadata[element?.name]?.recordsFileDate ? new Date(requireMetadata[element?.name]?.recordsFileDate): ''}
                  disabled={!showButton || false}
                  onChange={(date) =>  handleChangeMeta(date, element?.name, "recordsFileDate")}
                />
              </div>
              <div className={classes.uploadFileInput}>
                <label className={classes.inputLabelMeta}>
                  Cơ quan ban hành
                </label>
                <Select
                  name="governmentId"
                  className={classes.inputMeta}
                  disabled={!showButton || false}
                  value={requireMetadata[element?.name]?.governmentId}
                  onChange={(e) => handleChangeMeta(e, element?.name)}
                >
                  {recordsGovernment && recordsGovernment.length > 0 ? (
                    recordsGovernment.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Không có tỉnh thành nào</MenuItem>
                  )}
                </Select>
              </div>
              <div className={classes.uploadFileInput}>
                <label className={classes.inputLabelMeta}>
                  Tiêu đề văn bản
                </label>
                <input
                  type="text"
                  name="recordsFileDescription"
                  className={classes.inputMeta}
                  disabled={!showButton || false}
                  value={requireMetadata && requireMetadata[element?.name]?.recordsFileDescription ? requireMetadata[element?.name]?.recordsFileDescription : ''}
                  onChange={(e) => handleChangeMeta(e, element?.name)}
                />
              </div>
              <div className={classes.updateMetadata}>
                {!listFile[element?.name]?.uploadProgress &&
                  showButton &&
                  listFile[element?.name]?.name && (
                    <label
                      className={classes.removeButton}
                      onClick={() => onRemove(element?.name, listFile[element?.name]?.idRecordsFile)}
                    >
                      Xóa file
                    </label>
                  )}
                {showButton && (
                  <label
                    className={classes.updateButton}
                    onClick={() =>
                      onUpdate(
                        listFile[element?.name]?.idRecordsFile || 0,
                        element?.documentUploadId || "",
                        element?.recordsFileKindId || "",
                        element?.recordsFileTypeName || "",
                        element?.recordsFileTypeId || "",
                        element?.name
                      )
                    }
                  >
                    Cập nhật
                  </label>
                )}
              </div>
              <hr style={{ width: "100%" }}></hr>
            </div>
          )}
        </Fragment>
      ))}

        
          
      {item?.allowAddNew && (
        <Fragment>
        {
          componentDocument && componentDocument[item.id]?.length > 0 ? componentDocument[item.id]?.map((a)=>
          <AddDocuments
            idItem={a}
            key={item.id}
            requireMetadata={requireMetadata}
            item={item}
            showButton={showButton}
            handleChangeMeta={handleChangeMeta}
            addRowFile={addRowFile}
            onChange={onChange}
            onRemoveAddRowFile={onRemoveAddRowFile}
          ></AddDocuments>
          ) : ""
        }
         {
          componentText && componentText[item?.id]?.length > 0 ? componentText[item?.id]?.map((b)=>
          <AddText 
          idItem={b}
          key={b}
          requireMetadata={requireMetadata}
          item={item}
          handleChangeMeta={handleChangeMeta}
          addRowFile={addRowFile}
          onRemoveAddRowFile={onRemoveAddRowFile}
          showButton={showButton}
          recordsGovernment={recordsGovernment}
          reomveFileAddRow={reomveFileAddRow}
          onUpdate={onAddorUpdate}
          handleChangeFileAddRow={handleChangeFileAddRow}
        ></AddText>
          ) : ""
        }
          {showButton && optionAddRow === 0 ? (
            <div className={classes.addFileInput}>
              <label
                className={classes.updateButton}
                onClick={() => {
                  onAddComponentDocument(item.id)
                }}
              >
                Thêm tài liệu
              </label>
              <label
                className={classes.updateButton}
                onClick={() => {
                  onAddComponentText(item.id)
                }}
              >
                Thêm văn bản, quyết định
              </label>
            </div>
          ) : (
            ""
          )}
          {showButton && optionAddRow !== 0 ? (
            <div
              className={classes.goBack}
              onClick={() => {
                setOptionAddRow(0);
                setAddRowFile({ name: "", file: "", preview: "" });
              }}
            >
              <ArrowBackIosIcon fontSize="small" />
              Quay lại
            </div>
          ) : (
            ""
          )}
        
        
        </Fragment>
      )}
    </div>
  );
};

export default memo(UploadFileComponent);
