/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./left-sidebar.scss";
import * as userInfoAction from "./../../redux/store/user-info/user-info.store";
import ShowNotification from "../react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import {
  DomainUserSite,
  NotificationMessageType,
  removeCookies,
  TokenKey,
} from "../../utils/configuration";
import history from "./../../common/history";
import { withRouter } from "react-router-dom";
import UrlCollect from "./../../common/url-collect";
import { QUYHOACH } from "../../common/constant";

function LeftSideBarUserInfo(props) {
  // const { location } = props;
  const { recordsTypeId, recordsId } = props.match.params;
  const [recordsIdList, setRecordsId] = useState([]);
  const [recordsTypeByPlanningKind, setRecordsTypeByPlanningKind] = useState(
    []
  );
  useEffect( () => {
    async function getRecords() {
      await userInfoAction.GetRecordsId().then(
        (res) => {
          if (res && res.content) {
            setRecordsId(res.content);
          }
        },
        (err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
      await userInfoAction.GetListRecordsTypeByPlanningKind().then(
        (res) => {
          if (res && res.content) {
            setRecordsTypeByPlanningKind(res.content);
          }
        },
        (err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
    getRecords();
  }, []);
    // await userInfoAction.GetRecordsType().then(
    //   (res) => {
    //     if (res && res.content) {
    //       setRecordsType(res.content);
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  
  const onLogout = () => {
    removeCookies(TokenKey.token);
    window.location.replace(DomainUserSite);
  };
  return (
    <Box className="left-side-bar-user-info">
      <div className="item">
        <div
          className={`title-side-bar ${!recordsId ? "active" : ""}`}
          onClick={() => {
            history.push(UrlCollect.UserInfo);
          }}
        >
          Thông tin tài khoản
        </div>
      </div>
      {recordsIdList.length > 0 &&
        recordsIdList.map((item) => (
          <React.Fragment key={item.id}>
            <div className="item" key={item.id}>
              <Box
                display="flex"
                className={`title-side-bar ${
                  recordsId == item.id ? "active" : ""
                }`}
              >
                <div className="text">{item.name}</div>
                <div className="icon">
                  <IconsLine />
                </div>
              </Box>
              <div className="sub-item">
                <Box
                  className={`sub-item-line ${
                    recordsId == item.id && recordsTypeId === undefined
                      ? "active-sub"
                      : ""
                  }`}
                  display="flex"
                  onClick={() => {
                    history.push(UrlCollect.UserInfo + `/${item.id}/thong-tin`);
                  }}
                >
                  <div className="icon"></div>
                  <div className="name">Thông tin</div>
                </Box>
                {recordsTypeByPlanningKind.map(
                  (subitem) =>
                    item?.planningKindId === subitem.planningKindId && (
                      <React.Fragment key={subitem.id}>
                        <Box
                          className={`sub-item-line ${
                            recordsId == item.id &&
                            recordsTypeId == subitem.recordsType.id
                              ? "active-sub"
                              : ""
                          }`}
                          display="flex"
                          key={subitem.recordsType.id}
                          onClick={() => {
                            history.push(
                              UrlCollect.UserInfo +
                                `/${item.id}/${subitem.recordsType.id}`
                            );
                          }}
                        >
                          <div className="icon"></div>
                          <div className="name">{subitem.recordsType.name}</div>
                        </Box>
                      </React.Fragment>
                    )
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
      <div className="item logout">
        <div className="title-side-bar" onClick={onLogout}>
          Đăng xuất
        </div>
      </div>
    </Box>
  );
}

// const mapStateToProps = (state) => ({
//     loadingQueue: state.loadingState.loadingQueue,
// });

export default connect(null, null)(withRouter(LeftSideBarUserInfo));

const IconsLine = () => {
  return (
    <svg
      width="14"
      height="2"
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 1.04395H12.25"
        stroke="#333333"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
