import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const Create = (data) => {
  return service
      .post(ApiUrl.CreateComment, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const Like = (id) => {
    return service
        .post(ApiUrl.LikeComment + "/" + id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListAll = (data) => {
    const params = new URLSearchParams();
    params.append("content", data.content);
    data.referrenceId && params.append("referrenceId", data.referrenceId);
    params.append("referrenceType", data.referrenceType);
    params.append("pageSize", data.pageSize);
    params.append("pageIndex", data.pageIndex);

  return service
      .get(ApiUrl.GetListAllComment, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const GetListAllNoPage = (data) => {
    const params = new URLSearchParams();
    params.append("content", data.content);
    data.referrenceId && params.append("referrenceId", data.referrenceId);
    params.append("referrenceType", data.referrenceType);
    params.append("pageSize", data.pageSize);
    params.append("pageIndex", data.pageIndex);

    return service
        .get(ApiUrl.GetListAllNoPageComment, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};