/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./project-info.scss";
import * as userInfoAction from "./../../../redux/store/user-info/user-info.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "./../../../languages/vi-VN.json";
import {
  NotificationMessageType,
  MaxSizeImageUpload,
} from "../../../utils/configuration";
import Autocomplete from "@material-ui/lab/Autocomplete";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import * as config from "../../../utils/configuration";
import SunEditor from "suneditor-react";
function ProjectInfo(props) {
  const { isNew } = props;
  const { recordsId } = props.match.params;
  const [yearData, setYearData] = React.useState([]);
  const [getLookupPlanningKind, setGetLookupPlanningKind] = React.useState([]);
  const [province, setProvince] = React.useState({});
  const [category, setCategory] = React.useState({});
  const [categoryPlan, setCategoryPlan] = React.useState(1);
  const [categoryPlanName, setCategoryPlanName] = React.useState("");
  const [infoProject, setInfoProject] = React.useState({
    fromYear: 0,
    toYear: 0,
    planningYear: 0,
    name: "",
    planningKindId: 1,
    provinceId: null,
    categoryId: null,
    planningUnit: "",
    planningAgency: "",
    consultingUnit: "",
  });
  const [lookupProvince, setLookupProvince] = React.useState([]);
  const [lookup, setLookup] = React.useState([]);
  const [content, setContent] = React.useState();
  useEffect(() => {
    getGetLookupPlanningKind();
    const yearFake = new Date().getFullYear();
    const tmp = [];
    for (let index = yearFake - 15; index < yearFake + 15; index++) {
      tmp.push(index);
    }
    setYearData(tmp);
  }, []);
  const getGetLookupPlanningKind = async() => {
    await userInfoAction
      .GetLookupPlanningKind()
      .then((res) => {
        if (res && res.content && res.content) {
          setGetLookupPlanningKind(res.content);
        }
      })
      .catch((err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      });
  };
  useEffect(() => {
    if (recordsId && !isNew) {
      userInfoAction
        .GetRecordsDetail(recordsId)
        .then((res) => {
          if (res && res.content && res.content.id) {
            setInfoProject(res.content);
            setContent(res.content.introduce);
            setCategoryPlan(res.content.planningKindId);
            userInfoAction
              .GetLookupProvince()
              .then((res1) => {
                if (res1 && res1.content && res1.content) {
                  setLookupProvince(res1.content);
                  setProvince(
                    res1.content.find(
                      (item) => item.id === res.content.provinceId
                    )
                  );
                }
              })
              .catch((err) => {
                err &&
                  err.errorType &&
                  ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                  );
              });
            userInfoAction
              .GetLookupPlanningCategoryByPlanningKind(infoProject.planningKindId)
              .then((res2) => {
                if (res2 && res2.content && res2.content) {
                  setLookup(res2.content);
                  setCategory(
                    res2.content.find(
                      (item) => item.id === res.content.categoryId
                    )
                  );
                }
              })
              .catch((err) => {
                err &&
                  err.errorType &&
                  ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                  );
              });
          }
        })
        .catch((err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        });
    } else {
      userInfoAction
        .GetLookupProvince()
        .then((res1) => {
          if (res1 && res1.content && res1.content) {
            setLookupProvince(res1.content);
          }
        })
        .catch((err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        });
      userInfoAction
        .GetLookupPlanningCategoryByPlanningKind(infoProject.planningKindId)
        .then((res2) => {
          if (res2 && res2.content && res2.content) {
            setLookup(res2.content);
          }
        })
        .catch((err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        });
    }
  }, [recordsId, infoProject.planningKindId]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInfoProject({ ...infoProject, [name]: value });
  };
  const handleChangeCategory = (event) => {
    setCategoryPlan(event.target.value);
    setInfoProject({ ...infoProject, planningKindId: event.target.value });
    const tmp = getLookupPlanningKind.find(item=>item.id === event.target.value)
    if(tmp){
      setCategoryPlanName(tmp.name)
    }
  };
  const onSummit = () => {
    if (!infoProject.fromYear || !infoProject.toYear) {
      ShowNotification(
        viVN.Errors.CreateProjectYearn,
        NotificationMessageType.Error
      );
      return;
    }
    if (!infoProject.planningYear) {
      ShowNotification(
        viVN.Errors.CreateProjectPlanningYear,
        NotificationMessageType.Error
      );
      return;
    }
    if (!infoProject.provinceId && categoryPlan === 1) {
      ShowNotification(
        viVN.Errors.CreateProjectProvinceId,
        NotificationMessageType.Error
      );
      return;
    }
    if (!infoProject.categoryId && (categoryPlan === 2 || categoryPlan === 3)) {
      ShowNotification(
        viVN.Errors.CreateProjectCategoryId,
        NotificationMessageType.Error
      );
      return;
    }
    if (!infoProject.name) {
      ShowNotification(
        viVN.Errors.CreateProjectName,
        NotificationMessageType.Error
      );
      return;
    }
    if (!infoProject.consultingUnit) {
      ShowNotification(
        viVN.Errors.CreateProjectPlanningUnit,
        NotificationMessageType.Error
      );
      return;
    }
    if (!content) {
      ShowNotification(
        viVN.Errors.CreateProjectContent,
        NotificationMessageType.Error
      );
      return;
    }
    if (isNew) {
      const tmp = {
        ...infoProject,
        introduce: content,
      };
      userInfoAction
        .CreateRecords(tmp)
        .then((res) => {
          if (res && res.content && res.content) {
            ShowNotification(
              viVN.Success.ChangeProjectSuccess,
              NotificationMessageType.Success
            );
            history.push(
              UrlCollect.UserInfo + `/${res.content.status.id}/thong-tin`
            );
            setInfoProject({});
            window.location.reload();
          }
        })
        .catch((err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        });
    } else {
      const tmp = {
        ...infoProject,
        introduce: content,
      };
      userInfoAction
        .UpdateRecords(tmp)
        .then((res) => {
          if (res && res.content && res.content) {
            ShowNotification(
              viVN.Success.ChangeProjectSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        });
    }
  };
  const onChangeContent = (editorContent) => {
    console.log("zooo1", editorContent);
    if (editorContent === "<p><br></p>") {
      setContent("");
    } else {
      setContent(editorContent);
    }
  };
  const onDeteleReocord = (id) => {
    userInfoAction
    .DeteleRecord(id)
    .then((res) => {
      if (res && res.content && res.content) {
        history.push(UrlCollect.UserInfo);
        window.location.reload();
        ShowNotification(
          viVN.Success.ChangeProjectSuccess,
          NotificationMessageType.Success
        );
      }
    })
    .catch((err) => {
      err &&
        err.errorType &&
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
    });
  }
  return (
    <div className="project-info-wrapper">
      <Box className="item-line" display="flex">
        <div className="label">
          Giai đoạn lập quy hoạch <label className="required" />
        </div>
        <FormControl variant="outlined">
          <Autocomplete
            options={yearData}
            value={infoProject.fromYear}
            onChange={(event, newValue) => {
              setInfoProject({ ...infoProject, fromYear: newValue });
            }}
            getOptionLabel={(option) => option.toString()}
            id="controllable-states-demo"
            renderInput={(params) => (
              <TextField {...params} placeholder="Năm" variant="outlined" />
            )}
          />
        </FormControl>

        <div className="text">đến</div>
        <FormControl variant="outlined">
          <Autocomplete
            options={yearData}
            value={infoProject.toYear}
            onChange={(event, newValue) => {
              setInfoProject({ ...infoProject, toYear: newValue });
            }}
            getOptionLabel={(option) => option.toString()}
            id="controllable-states-demo"
            renderInput={(params) => (
              <TextField {...params} placeholder="Năm" variant="outlined" />
            )}
          />
        </FormControl>
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">
          Năm lập quy hoạch <label className="required" />
        </div>
        <FormControl variant="outlined">
          <Autocomplete
            options={yearData}
            value={infoProject.planningYear}
            onChange={(event, newValue) => {
              setInfoProject({ ...infoProject, planningYear: newValue });
            }}
            getOptionLabel={(option) => option.toString()}
            id="controllable-states-demo"
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Năm lập kế hoạch"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">
          Loại quy hoạch <label className="required" />
        </div>
        <FormControl variant="outlined">
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            className="input-select"
            name="startYear"
            disabled={!isNew}
            value={categoryPlan}
            onChange={handleChangeCategory}
          >
            {
              getLookupPlanningKind.map((item)=>(
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>

      {categoryPlan === 1 ? (
        <Box className="item-line" display="flex">
          <div className="label">
            Quy hoạch tỉnh <label className="required" />
          </div>
          <FormControl variant="outlined">
            <Autocomplete
              options={lookupProvince}
              value={province}
              disabled={!isNew}
              onChange={(event, newValue) => {
                setProvince(newValue);
                setInfoProject({
                  ...infoProject,
                  provinceId: newValue?.id,
                  categoryId: null,
                });
              }}
              getOptionLabel={(option) => option.name}
              id="controllable-states-demo"
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </Box>
      ) : (
        categoryPlan === 2 || categoryPlan === 3 ? (
          <Box className="item-line" display="flex">
          <div className="label">
            {categoryPlanName} <label className="required" />
          </div>
          <FormControl variant="outlined">
            <Autocomplete
              options={lookup}
              value={category}
              onChange={(event, newValue) => {
                setCategory(newValue);
                setInfoProject({
                  ...infoProject,
                  categoryId: newValue?.id,
                  provinceId: null,
                });
              }}
              getOptionLabel={(option) => option.name}
              id="controllable-states-demo"
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </Box>
      ) : "")}

      <Box className="item-line" display="flex">
        <div className="label">
          Tên quy hoạch <label className="required" />
        </div>
        <TextField
          className="input"
          id="outlined-basic"
          variant="outlined"
          name="name"
          value={infoProject.name}
          onChange={handleChange}
        />
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">
          Cơ quan tổ chức <label className="required" />
        </div>
        <TextField
          className="input"
          id="outlined-basic"
          variant="outlined"
          name="planningUnit"
          value={infoProject.planningUnit}
          onChange={handleChange}
        />
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">
          Cơ quan lập quy hoạch <label className="required" />
        </div>
        <TextField
          className="input"
          id="outlined-basic"
          variant="outlined"
          name="planningAgency"
          value={infoProject.planningAgency}
          onChange={handleChange}
        />
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">Dự án đã phê duyệt</div>
        <TextField className="input" id="outlined-basic" variant="outlined" />
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">
          Tên tư vấn lập quy hoạch <label className="required" />
        </div>
        <TextField
          className="input"
          id="outlined-basic"
          variant="outlined"
          name="consultingUnit"
          value={infoProject.consultingUnit}
          onChange={handleChange}
        />
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">
          Giới thiệu quy hoạch <label className="required" />
        </div>
        <div className="input-sun-editor">
          <SunEditor
            enableToolbar={true}
            showToolbar={true}
            setContents={content}
            imageAccept=".jpg,.jpeg,.png,.gift,.svg,.tif"
            imageUploadSizeLimit={MaxSizeImageUpload}
            imageUploadUrl={`${config.APIUrlDefault}/api/admin/DocumentUpload/UploadImage`}
            videoFileInput={false}
            setOptions={{
              height: 300,
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "paragraphStyle",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "textStyle",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "audio",
                  "fullScreen",
                  "showBlocks",
                  "codeView",
                ],
              ],
            }}
            onChange={onChangeContent}
            onBlur={(event, editorContents) => onChangeContent(editorContents)}
          />
        </div>
      </Box>
      <Box className="item-line" display="flex">
        {!isNew && <Button className="button-deactivate" onClick={()=>onDeteleReocord(infoProject?.id)}>Xóa</Button>}
        <Button className="button-active" onClick={onSummit}>
          Cập nhật
        </Button>
      </Box>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//     loadingQueue: state.loadingState.loadingQueue,
// });

export default connect(null, null)(withRouter(ProjectInfo));
