import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";

function  AddDocuments(props) {
  const useStyles = makeStyles({
    uploadFile: {
      marginTop: 24,

      "& label": {
        marginBottom: 0,
      },
    },
    uploadFileLabel: {
      fontWeight: 500,
      lineHeight: "19px",
      color: "#383838",
      marginBottom: "10px !important",
    },
    inputLabel: {
      width: 350,
      padding: "14px 15px",
      backgroundColor: "#E0ECF5",
      color: "#383838",
    },
    inputLabelMeta: {
      width: 350,
      padding: "14px 15px",
      color: "#383838",
      borderRight: "1px solid #A5A5A5",
    },
    uploadFileInput: {
      height: 50,
      border: "1px solid #A5A5A5",
      borderRadius: "3px",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    fileName: {
      flex: 1,
      padding: "14px",
      border: "1px solid #A5A5A5",
      borderTop: 0,
      borderBottom: 0,
      height: 50,
    },
    uploadButton: {
      padding: "6px 14px",
      color: "#fff",
      backgroundColor: "#359AE1",
      borderRadius: "3px",
      margin: "0 10px",
      fontSize: 15,
      height: 32,

      "&:hover": {
        cursor: "pointer",
      },
    },
    removeButton: {
      padding: "6px 14px",
      color: "#fff",
      backgroundColor: "red",
      borderRadius: "3px",
      margin: "0 10px",
      fontSize: 15,
      height: 32,

      "&:hover": {
        cursor: "pointer",
      },
    },
    updateButton: {
      padding: "6px 14px",
      color: "#fff",
      backgroundColor: "#359AE1",
      borderRadius: "3px",
      margin: "0 10px",
      fontSize: 15,
      height: 32,

      "&:hover": {
        cursor: "pointer",
      },
    },
    inputMeta: {
      width: "calc(100% - 351px)",
      height: "100%",
      border: 0,
    },
    updateMetadata: {
      marginTop: 15,
      display: "flex",
      justifyContent: "flex-end",
    },
    addFileInput: {
      height: 50,
      border: "1px solid #A5A5A5",
      borderTop: "0px",
      borderRadius: "3px",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      justifyContent: "center",
    },
    addInputLabel: {
      width: 350,
      padding: "14px 15px",
      color: "#383838",
      border: "0px",
    },
    goBack: {
      marginTop: 15,
      color: "#8b8787",
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  const {
    requireMetadata,
    item,
    handleChangeMeta,
    addRowFile,
    onChange,
    onRemoveAddRowFile,
    idItem,
    key,
    showButton
  } = props;
  return (
    <div className={classes.uploadFileInput} style={{ marginTop: 15 }} key={key}>
      <input
        type="text"
        className={classes.addInputLabel}
        name="recordsFileTypeName"
        value={requireMetadata[idItem]?.recordsFileTypeName}
        onChange={(e) => handleChangeMeta(e, idItem, "",item)}
        placeholder="Nhập tên tài liệu"
      />
      <input
        id={`add-element-${idItem}`}
        type="file"
        name={item?.id}
        className="media-file"
        hidden
        onChange={(e) => onChange(e, false, 2, idItem, item)}
      />
      {addRowFile[idItem]?.name ? (
        <a
          href={`${addRowFile[idItem]?.preview || ""}`}
          className={classes.fileName}
          target="_blank"
          rel="noopener noreferrer"
        >
          {addRowFile[idItem]?.name}
        </a>
      ) : (
        <a className={classes.fileName} rel="noopener noreferrer"></a>
      )}
      
      {!addRowFile[idItem]?.name && showButton ? (
        <label htmlFor={`add-element-${idItem}`} className={classes.uploadButton}>
          Chọn file
        </label>
      ) : (
          showButton ? (
            <label
            className={classes.removeButton}
            onClick={() =>
              onRemoveAddRowFile(idItem, requireMetadata[idItem]?.idRecordsFile)
            }
          >
            Xóa file
          </label> 
          ) : ""
      )}
    </div>
  );
}
export default AddDocuments;
