import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  GetListRecordsFile,
  GetRecordsType,
  GetListRecordsTypeByPlanningKind,
  GetLookupGovernment,
} from "../../redux/store/user-info/user-info.store";
import UploadFileComponent from "../user-info/upload-file";
import * as apiConfig from "../../api/api-config";
import * as planningAction from "../../redux/store/planning/planning.store";
import * as viVN from "../../languages/vi-VN.json";
import "./style.view.scss";
import { useHistory } from "react-router-dom";
import { QUYHOACH } from "../../common/constant";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

function ThongTinQuyHoach({ match = {} }) {
  const { planningId, recordsTypeId } = match.params;
  const history = useHistory();
  const [planningData, setPlanningData] = useState({
    name: "",
    records: [],
  });
  const [recordsGovernment, setRecordsGovernment] = useState([]);
  const [componentDocument, setComponentDocument] = React.useState([]);
  const [componentText, setComponentText] = React.useState([]);
  const [listInput, setListInput] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState([]);
  const [listFile, setListFile] = useState({});
  const [recordData, setRecordData] = useState([
    {
      id: -1,
      name: "",
    },
  ]);
  const [addRowFile, setAddRowFile] = React.useState({});
  const [tabActive, setTabActive] = useState(-1);
  const [tabs, setTabs] = useState([]);
  const [planningKindType, setPlanningKindType] = useState(0);
  const { register, errors, clearErrors } = useForm({
    mode: "all",
  });
  const [requireMetadata, setRequireMetadata] = useState({});
  const isLastStep = activeStep === steps.length;

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  useEffect(() => {
    async function onGetLookupGovernment() {
      await GetLookupGovernment().then(
        (res) => {
          if (res && res.content) {
            setRecordsGovernment(res.content);
          }
        },
        (err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
    onGetLookupGovernment();
  }, [tabActive]);
  console.log("recordsGovernment", recordsGovernment);
  const handleBack = () => {
    history.goBack();
  };

  const handleChangeTab = (newValue) => {
    setTabActive(newValue);
  };

  const handlePreviewFile = (path) => {
    if (!path) {
      return;
    }

    const filePath = path.split("Uploads")[1];
    return `${apiConfig.api}Uploads/${filePath}`;
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    GetRecordsType({
      recordsTypeId: recordsTypeId,
    }).then((res) => {
      setRecordData(res?.content);

      // const newTabs = res?.content?.map((item) => ({
      //   id: item.id,
      //   name: item.name,
      // }));
      // setTabs(newTabs);
    });
  }, [recordsTypeId, tabActive]);
  useEffect(() => {
    if (tabActive !== -1) {
      GetListRecordsFile({
        recordsId: planningId,
        recordsTypeId: tabActive,
      }).then((res) => {
        let newInputFile= {};
        let cmText = [];
        let cmDoc = [];
        for (let i = 0; i < res?.content?.items?.length; i++) {
          if(res?.content?.items[i]?.recordsFileKindId) {
            newInputFile = {
              ...newInputFile,
              [res?.content?.items[i]?.recordsFileKindId]: {
                idRecordsFile: res?.content?.items[i]?.id,
                recordsFileTypeId: res?.content?.items[i]?.recordsFileTypeId,
                documentUploadId: res?.content?.items[i]?.documentUploadId,
                recordsFileKindId: res?.content?.items[i]?.recordsFileKindId,
                recordsFileTypeName: res?.content?.items[i]?.recordsFileTypeName,
                governmentId: res?.content?.items[i]?.governmentId,
                recordsFileDate: res?.content?.items[i]?.recordsFileDate,
                recordsFileNo: res?.content?.items[i]?.recordsFileNo,
                recordsFileDescription: res?.content?.items[i]?.recordsFileDescription,
                name: res?.content?.items[i]?.files?.fileName,
                preview: handlePreviewFile(
                  res?.content?.items[i]?.files?.filePreview
                ),
              },
            };
          } else {
            newInputFile = {
              ...newInputFile,
              [res?.content?.items[i]?.id]: {
                idRecordsFile: res?.content?.items[i]?.id,
                recordsFileTypeId: res?.content?.items[i]?.recordsFileTypeId,
                documentUploadId: res?.content?.items[i]?.documentUploadId,
                recordsFileKindId: res?.content?.items[i]?.recordsFileKindId,
                recordsFileTypeName: res?.content?.items[i]?.recordsFileTypeName,
                governmentId: res?.content?.items[i]?.governmentId,
                recordsFileDate: res?.content?.items[i]?.recordsFileDate,
                recordsFileNo: res?.content?.items[i]?.recordsFileNo,
                recordsFileDescription: res?.content?.items[i]?.recordsFileDescription,
                name: res?.content?.items[i]?.files?.fileName,
                preview: handlePreviewFile(
                  res?.content?.items[i]?.files?.filePreview
                ),
              },
            };
           
          }
          if(!res?.content?.items[i]?.recordsFileNo && !res?.content?.items[i]?.governmentId && !res?.content?.items[i]?.recordsFileDate && !res?.content?.items[i]?.recordsFileDescription) {
            if(res?.content?.items[i]?.recordsFileKindId) {
              cmDoc = {
                ...cmDoc,
                [res?.content?.items[i]?.recordsFileKindId]: [
                  res?.content?.items[i]?.recordsFileKindId
                ]
              }
              // cmDoc.push(res?.content?.items[i]?.recordsFileKindId)
            }
           
          }else{
            // if(res?.content?.items[i]?.recordsFileKindId) {
            //   cmText.push(res?.content?.items[i]?.recordsFileKindId)
            // }
            cmText = {
              ...cmText,
              [res?.content?.items[i]?.recordsFileKindId]: [
                res?.content?.items[i]?.recordsFileKindId
              ]
            }
          }
        }
        setListFile(newInputFile);
        setAddRowFile(newInputFile);
        setRequireMetadata(newInputFile);
        setComponentDocument(cmDoc);
        setComponentText(cmText);
      });
    }
  }, [planningId, recordData, tabActive]);
  useEffect(() => {
    const newFileKinds = recordData.find(
      (item) => item?.id === tabActive
    )?.recordsFileKinds;
    setActiveStep(1);

    if (newFileKinds?.length) {
      let newData = {};
      for (let i = 0; i < newFileKinds?.length; i++) {
        const step = newFileKinds[i]?.wizardStep;
        const object = {
          label: newFileKinds[i]?.name,
          id: newFileKinds[i]?.id,
          field: [],
          required: false,
          allowAddNew: newFileKinds[i]?.allowAddNew,
          recordsFileKindId: newFileKinds[i]?.id,
          recordsFileTypeId: newFileKinds[i]?.recordsTypeId,
        };
       
        for (let j = 0; j < newFileKinds[i]?.recordsFileTypes?.length; j++) {
          object.field.push({
            label: newFileKinds[i]?.recordsFileTypes[j]?.name,
            name: newFileKinds[i]?.recordsFileTypes[j]?.id,
            isRequireMetadata: newFileKinds[i]?.recordsFileTypes[j]?.isRequireMetadata,
            recordsFileKindId: newFileKinds[i]?.id,
            recordsFileTypeId: newFileKinds[i]?.recordsFileTypes[j]?.id,
            recordsFileTypeName: newFileKinds[i]?.recordsFileTypes[j]?.name,
          });
        }
        if (newData[`${step}`]?.length) {
          newData[`${step}`].push(object);
        } else {
          newData[`${step}`] = [object];
        }
      }
      setSteps(Object.keys(newData));
      setListInput(newData);
    }
  }, [recordData, tabActive]);

  useEffect(() => {
    planningAction
      .getRecordDetail(planningId)
      .then((res) => {
        let newData = [
          {
            label: "Giai đoạn lập quy hoạch",
            value: `${res.content?.fromYear} - ${res.content?.toYear}`,
          },
          {
            label: "Năm lập quy hoạch",
            value: res.content?.planningYear,
          },
          {
            label: "Loại quy hoạch",
            value:
              res?.content?.planningKindId === QUYHOACH.TINH
                ? "Quy hoạch tỉnh"
                : "Quy hoạch cấp Quốc gia, Cấp vùng",
          },
          {
            label:
              res?.content?.planningKindId === QUYHOACH.TINH
                ? "Quy hoạch tỉnh"
                : "Quy hoạch cấp Quốc gia, Cấp vùng",
            value:
              res?.content?.planningKindId === QUYHOACH.TINH
                ? res.content?.provinceName || "--"
                : res?.content?.categoryName || "--",
          },
          {
            label: "Tên quy hoạch",
            value: res.content?.name || "--",
          },
          {
            label: "Cơ quan tổ chức",
            value: res.content?.planningUnit || "--",
          },
          {
            label: "Cơ quan lập quy hoạch",
            value: res.content?.planningAgency || "--",
          },
          {
            label: "Dự án đã phê duyệt",
            value: res.content?.note || "--",
          },
          {
            label: "Tên tư vấn lập quy hoạch",
            value: res.content?.consultingUnit || "--",
          },
        ];
        setPlanningData({
          name: res.content?.name,
          records: newData,
        });
        setPlanningKindType(res.content.planningKindId);
        GetListRecordsTypeByPlanningKind().then(
          (res1) => {
            if (res1 && res1.content) {
              const newTabs1 = res1.content.filter(
                (item) => item.planningKindId === res.content.planningKindId
              );
              const newTabs2 = newTabs1.map((item) => ({
                id: item.recordsType.id,
                name: item.recordsType.name,
              }));
              setTabs(newTabs2);
            }
          },
          (err) => {
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
      })
      .catch((err) => {});
  }, [planningId, tabActive]);
  return (
    <div className="thong-tin-quy-hoach-page">
      <div className="container">
        <div className="page-header">
          <Button
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            className="button-back"
            onClick={handleBack}
          >
            Quay lại
          </Button>
          <h1 className="page-title">{planningData?.name}</h1>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={3} style={{paddingTop: 0}}>
            <div className="sub-item">
              {[
                {
                  name: "Thông tin",
                  id: -1,
                },
                ...tabs,
              ].map((subitem) => 
                (<React.Fragment key={subitem.id}>
                  <Box
                    className={`sub-item-line ${
                      tabActive == subitem.id ? "active-sub" : ""
                    }`}
                    display="flex"
                    key={subitem.id}
                    onClick={() => {
                      handleChangeTab(subitem.id);
                    }}
                  >
                    <div className="icon"></div>
                    <div className="name">{subitem.name}</div>
                  </Box>
                </React.Fragment>
              ))}
            </div>
          </Grid>
          <Grid item xs={9} className="content-right">
            <div >
              {tabActive === -1 && (
                <>
                  <Grid container spacing={3}>
                    {planningData.records.map((item, index) => (
                      <Fragment key={index}>
                        <Grid item xs={4}>
                          <span className="field-label">{item.label}</span>
                        </Grid>
                        <Grid item xs={8}>
                          {item.value}
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </>
              )}
              {tabActive !== -1 && (
                <>
                  {steps.length > 1 && (
                    <Stepper activeStep={activeStep - 1}>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel />
                        </Step>
                      ))}
                    </Stepper>
                  )}
                  {activeStep === 1 &&
                    listInput[`${activeStep}`]?.map((item, index) => (
                      <UploadFileComponent
                        index={index + 1}
                        item={item}
                        listFile={listFile}
                        onChange={null}
                        onRemove={null}
                        key={index}
                        register={register}
                        errors={errors}
                        clearErrors={clearErrors}
                        showButton={false}
                        componentDocument={componentDocument}
                        setComponentDocument={setComponentDocument}
                        componentText={componentText}
                        setComponentText={setComponentText}
                        requireMetadata={requireMetadata}
                        addRowFile={addRowFile}
                        recordsGovernment={recordsGovernment}

                      />
                    ))}
                  {activeStep === 2 &&
                    listInput[`${activeStep}`]?.map((item, index) => (
                      <UploadFileComponent
                        index={
                          listInput[`${activeStep - 1}`]?.length + index + 1
                        }
                        item={item}
                        listFile={listFile}
                        onChange={null}
                        onRemove={null}
                        key={index}
                        register={register}
                        errors={errors}
                        clearErrors={clearErrors}
                        showButton={false}
                        componentDocument={componentDocument}
                        setComponentDocument={setComponentDocument}
                        componentText={componentText}
                        setComponentText={setComponentText}
                        requireMetadata={requireMetadata}
                        recordsGovernment={recordsGovernment}
                        addRowFile={addRowFile}
                      />
                    ))}
                  <Box mt={6} textAlign="center">
                    {steps.length > 1 && activeStep > 1 && (
                      <Button
                        className="button-deactivate"
                        onClick={handlePreviousStep}
                      >
                        Quay lại
                      </Button>
                    )}
                    {steps.length > 1 && !isLastStep && (
                      <Button
                        className="button-active"
                        onClick={handleNextStep}
                      >
                        Tiếp tục
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//     loadingQueue: state.loadingState.loadingQueue,
// });

export default connect(null, null)(ThongTinQuyHoach);
