import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import { geolocated } from "react-geolocated";

import UrlCollect from "../../../../common/url-collect";
import history from "../../../../common/history";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import * as viVN from "../../../../languages/vi-VN.json";
import uongBiCoordinate from "../../../../common/uongbi_coordinate";
import * as mapHelper from "../../../../common/map";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../../../utils/configuration";


function SearchPlanningView(props: any) {
  const { dataSelectBoxSearch, setSelected } = props;

  function handleClickCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position: any) => {
      if (position && position.coords && checkAddressInPolyon(position.coords.longitude, position.coords.latitude)) {
        history.push(
          `${UrlCollect.SyntheticPlanning}?lat=${position.coords.latitude}&lng=${position.coords.longitude}&searchType=place`
        );
        if (
          props.openLayerController &&
          props.openLayerController.placeSearch
        ) {
          props.openLayerController.placeSearch([
            position.coords.longitude,
            position.coords.latitude,
          ]);
        }
      }
    },(error) => {
      ShowNotification(
        viVN.Errors.OpenCoordinateCurrent,
        NotificationMessageType.Error,
        NotificationPosition.TopRight
      );
    });
  }

  function checkAddressInPolyon(x: any, y: any) {
    let isInside = false;
    props.listPolygon &&
      props.listPolygon.map((itemPolygon: any) => {
        if (
          mapHelper.isPointInPolygon(y, x, itemPolygon)
        ) {
          isInside = true;
          return;
        }
      });
    if (isInside) {
      history.push(
        `${UrlCollect.SyntheticPlanning}?lng=${x}&lat=${y}&searchType=place`
      )
      return true;
    } else {
      ShowNotification(
        viVN.Errors.CoordinateOutSitePolygon,
        NotificationMessageType.Warning,
        NotificationPosition.Center
      );
    }
    return false;
  }

  return (
    <div className="row no-gutters align-items-center">
      <div className="col-10">
        <Select
          options={dataSelectBoxSearch}
          onChange={(newValue) => setSelected(newValue)}
          placeholder="Nhập tên quy hoạch - dự án"
          className="slAutocomplete"
          classNamePrefix="select"
        />
      </div>
      <div className="col-2 text-right">
        <button
          className="bg-transparent border-0"
          onClick={handleClickCurrentLocation}
        >
          <img
            src={require("../../../../assets/icon/locate.svg")}
            alt="Planning Name"
          />
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
  listPolygon: state.mapData.listPolygon,
});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(SearchPlanningView));
