import React from 'react';
import './layout-map-page.scss';
import TopView from "../top/top.view.jsx";
import HeaderView from '../header/header.view.jsx';
import LoadingWithApiQueue from '../loading/loading-with-queue.view';

export default class LayoutMapPageView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="layout-map-page-container">
                <TopView />
                <HeaderView match={this.props.match} />
                <div>
                    {this.props.children}
                </div>
                <LoadingWithApiQueue />
            </div>
        )
    }
}

