import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
function AddText(props) {
  const useStyles = makeStyles({
    uploadFile: {
      marginTop: 24,

      "& label": {
        marginBottom: 0,
      },
    },
    uploadFileLabel: {
      fontWeight: 500,
      lineHeight: "19px",
      color: "#383838",
      marginBottom: "10px !important",
    },
    inputLabel: {
      width: 350,
      padding: "14px 15px",
      backgroundColor: "#E0ECF5",
      color: "#383838",
    },
    inputLabelMeta: {
      width: 350,
      padding: "14px 15px",
      color: "#383838",
      borderRight: "1px solid #A5A5A5",
    },
    uploadFileInput: {
      height: 50,
      border: "1px solid #A5A5A5",
      borderRadius: "3px",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    fileName: {
      flex: 1,
      padding: "14px",
      border: "1px solid #A5A5A5",
      borderTop: 0,
      borderBottom: 0,
      height: 50,
    },
    uploadButton: {
      padding: "6px 14px",
      color: "#fff",
      backgroundColor: "#359AE1",
      borderRadius: "3px",
      margin: "0 10px",
      fontSize: 15,
      height: 32,

      "&:hover": {
        cursor: "pointer",
      },
    },
    removeButton: {
      padding: "6px 14px",
      color: "#fff",
      backgroundColor: "red",
      borderRadius: "3px",
      margin: "0 10px",
      fontSize: 15,
      height: 32,

      "&:hover": {
        cursor: "pointer",
      },
    },
    updateButton: {
      padding: "6px 14px",
      color: "#fff",
      backgroundColor: "#359AE1",
      borderRadius: "3px",
      margin: "0 10px",
      fontSize: 15,
      height: 32,

      "&:hover": {
        cursor: "pointer",
      },
    },
    inputMeta: {
      width: "calc(100% - 351px)",
      height: "100%",
      border: 0,
    },
    updateMetadata: {
      marginTop: 15,
      display: "flex",
      justifyContent: "flex-end",
    },
    addFileInput: {
      height: 50,
      border: "1px solid #A5A5A5",
      borderTop: "0px",
      borderRadius: "3px",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      justifyContent: "center",
    },
    addInputLabel: {
      width: 350,
      padding: "14px 15px",
      color: "#383838",
      border: "0px",
    },
    goBack: {
      marginTop: 15,
      color: "#8b8787",
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  const {
    requireMetadata,
    item,
    handleChangeMeta,
    addRowFile,
    showButton,
    recordsGovernment,
    idItem,
    handleChangeFileAddRow,
    reomveFileAddRow,
    onUpdate
  } = props;
  
  return (
    <div style={{ marginTop: 15 }}>
      <div className={classes.uploadFileInput}>
        <input
          type="text"
          className={classes.addInputLabel}
          name="recordsFileTypeName"
          value={requireMetadata[idItem]?.recordsFileTypeName}
          onChange={(e) => handleChangeMeta(e, idItem)}
          placeholder="Nhập tên tài liệu"
        />
        <input
          id={idItem}
          type="file"
          name={idItem}
          className="media-file"
          onChange={(e) => handleChangeFileAddRow(e)}
          hidden
        />
        {addRowFile[idItem]?.name ? (
          <a
            href={`${addRowFile[idItem]?.preview || ""}`}
            className={classes.fileName}
            target="_blank"
            rel="noopener noreferrer"
          >
            {addRowFile[idItem]?.name}
          </a>
        ) : (
          <a className={classes.fileName} rel="noopener noreferrer"></a>
        )}
        {(!addRowFile[idItem]?.name && showButton) && (
          <label htmlFor={idItem} className={classes.uploadButton}>
            Chọn file
          </label>
        )}
      </div>
      <div>
        <div className={classes.uploadFileInput}>
          <label className={classes.inputLabelMeta}>Số hiệu văn bản</label>
          <input
            type="text"
            name="recordsFileNo"
            disabled={!showButton || false}
            className={classes.inputMeta}
            value={requireMetadata[idItem] ? requireMetadata[idItem]?.recordsFileNo : ""}
            onChange={(e) => handleChangeMeta(e, idItem)}
          />
        </div>
        <div className={classes.uploadFileInput}>
          <label className={classes.inputLabelMeta}>Ngày ban hành </label>
          <DatePicker
            name="recordsFileDate"
            selected={requireMetadata[idItem]?.recordsFileDate ? new Date(requireMetadata[idItem]?.recordsFileDate): ''}
            disabled={!showButton || false}
            onChange={(date) =>  handleChangeMeta(date, idItem, "recordsFileDate")}
          />
        </div>
        <div className={classes.uploadFileInput}>
          <label className={classes.inputLabelMeta}>Cơ quan ban hành</label>
          <Select
            name="governmentId"
            className={classes.inputMeta}
            disabled={!showButton || false}
            value={requireMetadata[idItem] ? requireMetadata[idItem]?.governmentId: ""}
            onChange={(e) => handleChangeMeta(e, idItem)}
          >
            {recordsGovernment && recordsGovernment.length > 0 ? (
              recordsGovernment.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">Không có tỉnh thành nào</MenuItem>
            )}
          </Select>
        </div>
        <div className={classes.uploadFileInput}>
          <label className={classes.inputLabelMeta}>Tiêu đề văn bản</label>
          <input
            type="text"
            name="recordsFileDescription"
            className={classes.inputMeta}
            disabled={!showButton || false}
            value={requireMetadata[idItem]?.recordsFileDescription}
            onChange={(e) => handleChangeMeta(e, idItem)}
          />
        </div>
        {
          showButton ? (<div className={classes.updateMetadata}>
            { addRowFile[idItem]?.name && (
                <label
                  className={classes.removeButton}
                  onClick={() => reomveFileAddRow(idItem, addRowFile[idItem]?.idRecordsFile)}
                >
                  Xóa file
                </label>
              )}

              <label
                className={classes.updateButton}
                onClick={() =>
                  onUpdate(
                    addRowFile[idItem]?.idRecordsFile || 0,
                    item?.documentUploadId || "",
                    item?.recordsFileKindId || "",
                    item?.recordsFileTypeName || "",
                    item?.recordsFileTypeId || "",
                    idItem
                  )
                }
              >
                Cập nhật
              </label>
      
          </div>) : ""
        }
        
              <hr style={{ width: "100%" }}></hr>
      </div>
    </div>
  );
}

export default AddText;
