/* eslint-disable no-useless-constructor */
import React from "react";
import { connect } from "react-redux";

function PublishUnit(props) {
    const { loading } = props;

    return (
        <div className="row">
            <div className="col-12">
                <div className="header-widget">
                    <div className="title">
                        Liên hệ cơ quan ban hành
                            </div>
                </div>
                <div className="content-result planning-result-more-info">
                    <div className="more-title">Tên tổ chức:</div> Bộ kế hoạch và đầu tư<hr />
                    <div className="more-title">Số điện thoại:</div> <a href="tel:02438232494">(84) 24 3823 2494</a><hr />
                    <div className="more-title">Email:</div> <a href="mailto: qlqh@gmail.com">qlqh@gmail.com</a><hr />
                    <div className="more-title">Địa chỉ:</div> <br />6B, Hoàng Diệu, Ba Đình, Hà Nội<hr />
                    <div className="more-title">Thành phố:</div> Hà Nội
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    loading: state.app.loading,
});

export default connect(mapStateToProps)(PublishUnit);
