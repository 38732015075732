/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import "./left-menu.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import ReactTooltip from "react-tooltip";
import * as projectAction from "../../../redux/store/project/project.store";
import $ from 'jquery';
const replaceString = require('replace-string');

function RenderInfoTable(props: any) {
  const {
      data,
      handleClickShowAllMapResult,
      isShowMapResult,
      headerTable,
      planningId,
      layerId
  } = props;



  const onDownloadExcelOffline = () => {
    var tab_text = "<table border='2px'><thead><tr>";
    var _header = "";
    headerTable &&
      headerTable.forEach((_item: any, _index: number) => {
        const _th =
          "<th style='color:white; background-color:#007bff; height:70px;'>" +
          (_item.alias || "") +
          "</th>";
        _header += _th;
      });
    tab_text += _header + "</tr></thead><tbody>";

    var _body = "";
    data &&
      data.forEach((_item: any, _i: number) => {
        _body += "<tr>";
        var _row = "";
        headerTable.forEach((_title: any, _index: number) => {
          const _td = "<td>" + (_item[_title.col] || "") + "</td>";
          _row += _td;
        });
        _body += _row + "</tr>";
      });

    tab_text += _body + "</tbody></table>";
    tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
    tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
    tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

    const sa = window.open(
      "data:application/vnd.ms-excel," + encodeURIComponent(tab_text)
    );
    return sa;
  };
  
  function addToProject(item:any) {
    if(item.geo){
        let geoStr = item.geo;
        geoStr = $.trim(geoStr);
        geoStr = geoStr.replace('MULTILINESTRING','');
        geoStr = geoStr.replace('MULTIPOLYGON','');
        geoStr = geoStr.replace('((','','');
        geoStr = geoStr.replace('))','');
        let arrStr = [];
        geoStr = $.trim(geoStr);
        if(geoStr){
            arrStr = geoStr.split(',');
            geoStr = arrStr[0];
            arrStr = geoStr.split(' ');
            if(arrStr && Array.isArray(arrStr) && arrStr.length == 2){
                let data = {'planningId':planningId,
                    'layerId':layerId,
                    'gId':item.gid,
                    'name':item.name,
                    'isActive':true,
                    'object': {'coordinate':[arrStr[0],arrStr[1]]}
                }
                return new Promise((resolve, reject) => {
                    projectAction.CreateProject(data).then(
                        (res) => {
                            resolve(res);
                        },
                        (err) => {
                            reject(err);
                        }
                    );
                });
            }
        }
    }
  }

  function hightLightLayer(item:any) {
    if(item && item.geo){
      let boundaries = [];
      boundaries.push(item.geo);
      props.openLayerController.functionHightLightPolygon([]);
      props.openLayerController.functionHightLightPolygon(boundaries);
    }
  }

  return (
    <div className="render-map-child-container pt-2 pl-2 pr-2">
        {headerTable && Array.isArray(headerTable) && headerTable.length >0 ?(
            <div>
                <div className="d-flex align-items-center justify-content-between pt-2 pb-2 pl-2">
                    <div className="map-result-number d-flex align-items-center">
                        <label className="mb-0">
                            Có <b>{data.length}</b> kết quả
                        </label>
                        <span
                            onClick={onDownloadExcelOffline}
                            className="text-success ml-3 export-excel"
                            data-tip="Xuất file excel"
                        >
                        <FontAwesomeIcon icon={faFileExcel} />
                      </span>
                        <ReactTooltip place="right" effect="solid" />
                    </div>
                    <div className="map-result-view">
                        {!isShowMapResult ? (
                            <img
                                src={require("../../../assets/icon/last.svg")}
                                alt="Last"
                                className="img-last"
                                onClick={handleClickShowAllMapResult}
                            ></img>
                        ) : (
                            <img
                                src={require("../../../assets/icon/last.svg")}
                                alt="Last"
                                className="img-last-reverse"
                                onClick={handleClickShowAllMapResult}
                            ></img>
                        )}
                        <img
                            width={16}
                            src={require("../../../assets/icon/close-black.svg")}
                            alt="Close"
                            className="img-close ml-3"
                        ></img>
                    </div>
                </div>
                <table className="table table-map-result">
                    <thead>
                    <tr>
                        {headerTable && headerTable.length > 0 && (
                            <th key={8888} className="text-truncate bg-primary text-white"></th>
                        )}
                        {headerTable &&
                        headerTable.length > 0 &&
                        headerTable.map((item: any, index: number) => (
                            <th
                                scope="col"
                                key={index}
                                className="text-truncate bg-primary text-white"
                            >
                                {item.alias}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data &&
                    data.length > 0 &&
                    data.map((item: any, index: number) => (
                        <tr key={index}>
                            {headerTable &&
                            headerTable.length > 0 && (
                                <td>
                                    <button onClick={() => hightLightLayer(item)} type="button" className="btn btn-sm btn-primary">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search-location"
                                             className="svg-inline--fa fa-search-location fa-w-16" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M505.04 442.66l-99.71-99.69c-4.5-4.5-10.6-7-17-7h-16.3c27.6-35.3 44-79.69 44-127.99C416.03 93.09 322.92 0 208.02 0S0 93.09 0 207.98s93.11 207.98 208.02 207.98c48.3 0 92.71-16.4 128.01-44v16.3c0 6.4 2.5 12.5 7 17l99.71 99.69c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.59.1-33.99zm-297.02-90.7c-79.54 0-144-64.34-144-143.98 0-79.53 64.35-143.98 144-143.98 79.54 0 144 64.34 144 143.98 0 79.53-64.35 143.98-144 143.98zm.02-239.96c-40.78 0-73.84 33.05-73.84 73.83 0 32.96 48.26 93.05 66.75 114.86a9.24 9.24 0 0 0 14.18 0c18.49-21.81 66.75-81.89 66.75-114.86 0-40.78-33.06-73.83-73.84-73.83zm0 96c-13.26 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path>
                                        </svg>
                                    </button>
                                </td>
                            )}
                            {headerTable &&
                            headerTable.length > 0 &&
                            headerTable.map((item1: any, index: number) => (
                                <td scope="row" key={index}>
                                    {item[item1.col]}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        ):(
            <div className="text-center">Không có kết quả tìm kiếm phù hợp</div>
        )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  headerTable: state.mapData.headerTable,
  openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetListPlanningRelationByPlanningId:
        MapDataStore.GetListPlanningRelationByPlanningId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RenderInfoTable);
