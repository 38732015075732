import { Box, Button, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./account-info.scss";
import * as acountAction from "./../../../redux/store/account/account.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "./../../../languages/vi-VN.json";
import {
    NotificationMessageType,
  } from "../../../utils/configuration";
function AccountInfo(props) {
  const [accountInfo, setAccountInfo] = useState({})
  useEffect(() => {
    acountAction.GetUserAccountDetail().then((res) => {
        if(res && res.content && res.content.id){
            setAccountInfo(res.content)
        }else{
            window.location = '/dang-nhap';
        }
    }).catch((err) => {
        window.location = '/dang-nhap';
    });
  }, []);  

  const handleChange = (e) => {
      const { name, value } = e.target
      setAccountInfo({ ...accountInfo, [name]: value })
  }
  const onSummit = () => {
    acountAction.UpdateUserAccount2(accountInfo).then((res) => {
        console.log("res", res);
       if(res && res.content) {
        ShowNotification(
            viVN.Success.ChangeAccountSuccess,
            NotificationMessageType.Success
          );
       }
      }).catch((err) => {
        ShowNotification(
          viVN.Errors[(err && err.errorType)],
          NotificationMessageType.Error)
      })
  }
  return (
    <div className="account-info-wrapper">
      <Box className="item-line" display="flex">
        <div className="label">Tên đăng nhập</div>
        <TextField className="input" name="email" id="outlined-basic" variant="outlined" value={accountInfo.email} onChange={handleChange} />
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">Số điện thoại</div>
        <TextField className="input" name="phoneNumber" id="outlined-basic" variant="outlined" value={accountInfo.phoneNumber} onChange={handleChange} />
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">Email</div>
        <TextField className="input" name="email" id="outlined-basic" variant="outlined" value={accountInfo.email}  onChange={handleChange} />
      </Box>
      <Box className="item-line" display="flex">
        <div className="label">Tên cơ quan</div>
        <TextField className="input" name="company" id="outlined-basic" variant="outlined" onChange={handleChange} />
      </Box>
      <Box className="item-line" display="flex">
        <Button className="button-active" onClick={onSummit}>Cập nhật</Button>
      </Box>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//     loadingQueue: state.loadingState.loadingQueue,
// });

export default connect(null, null)(AccountInfo);
