/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Step, StepLabel, Stepper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  GetListRecordsFile,
  GetLookupGovernment,
  UpdateStep,
  UploadFileStream,
  UpdateRecordFile,
  DeteleRecordFileId,
} from "../../../redux/store/user-info/user-info.store";
import { NotificationMessageType } from "../../../utils/configuration";
import UploadFileComponent from "../upload-file";
import * as viVN from "../../../languages/vi-VN.json";
import * as apiConfig from "../../../api/api-config";

import "./style.view.scss";

function QuyHoach({ match = {}, recordData, isFinalStep }) {
  const { recordsId, recordsTypeId } = match.params;

  const [componentDocument, setComponentDocument] = React.useState([]);
  const [componentText, setComponentText] = React.useState([]);
  const [listInput, setListInput] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState([]);
  const [listFile, setListFile] = useState({});
  const [recordsGovernment, setRecordsGovernment] = useState([]);
  const [optionAddRow, setOptionAddRow] = React.useState(0);
  const [requireMetadata, setRequireMetadata] = useState({});
  const { handleSubmit, register, errors, clearErrors, reset } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  const [addRowFile, setAddRowFile] = React.useState({});
  const isLastStep = activeStep === steps.length;
  const handleChangeStep = (step) => () => {
    setActiveStep(step);
  };

  console.log("componentDocument", componentDocument);

  const handlePreviewFile = (path) => {
    if (!path) {
      return;
    }

    const filePath = path.split("Uploads")[1];
    return `${apiConfig.api}Uploads/${filePath}`;
  };
  const handleChangeMeta = (e, name_r, name_f) => {
    if(name_f === "recordsFileDate") {
      setRequireMetadata((prevState) => ({
        ...prevState,
        [name_r]: {
          ...prevState[name_r],
          recordsFileDate: e,
        },
      }));
      return;
    }
    const { value, name } = e.target;
    setRequireMetadata((prevState) => ({
      ...prevState,
      [name_r]: {
        ...prevState[name_r],
        [name]: value,
      },
    }));
  };
  const handleChangeFileAddRow = (event) => {
    const { name, files } = event.target;
    setAddRowFile((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        name: files[0].name,
        file: files[0],
      },
    }));
  };
  const reomveFileAddRow = (field) => {
    setAddRowFile(() => ({
      ...addRowFile,
        [field]: null,
    }));
  };
  
  const handleChangeFileContent = (event, optionAddRow, idFile, item) => {
    const { name, files } = event.target;
    if (files[0] && optionAddRow === 2) {
      if (!requireMetadata[idFile]?.recordsFileTypeName) {
        ShowNotification(
          viVN.Errors.NameRecordsFile,
          NotificationMessageType.Warning
        );
        return;
      }
      setAddRowFile((prevState) => ({
        ...prevState,
        [idFile]: {
          ...prevState[idFile],
          name: files[0].name,
          file: files[0],
        },
      }));
      UploadFileStream({
        file: files[0],
        recordsId: recordsId,
        // recordsFileTypeId: item?.id,
        recordsFileKindId:  item?.recordsFileKindId,
        recordsFileTypeName: requireMetadata[idFile]?.recordsFileTypeName,
      })
        .then((res) => {
          if (res?.content?.status) {
            ShowNotification(
              viVN.Success.UploadFileSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          setListFile((prevState) => ({
            ...prevState,
            [name]: {
              uploadProgress: 0,
            },
          }));
          ShowNotification(
            viVN.Errors[err && err.errorType],
            NotificationMessageType.Error
          );
        });
    } 
    if (files[0] && optionAddRow === 0) {
      UploadFileStream(
        {
          file: files[0],
          recordsId: recordsId,
          recordsFileTypeId: name,
        },
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            setListFile((prevState) => ({
              ...prevState,
              [name]: {
                ...prevState[name],
                uploadProgress: (loaded / total) * 100,
              },
            }));
          },
        }
      )
        .then((res) => {
          if (res?.content?.status) {
            ShowNotification(
              viVN.Success.UploadFileSuccess,
              NotificationMessageType.Success
            );
          }

          setListFile((prevState) => ({
            ...prevState,
            [name]: {
              name: files[0]?.name,
              preview: `${apiConfig.api}${res?.content?.filePreview}`,
              uploadProgress: 0,
            },
          }));
        })
        .catch((err) => {
          setListFile((prevState) => ({
            ...prevState,
            [name]: {
              uploadProgress: 0,
            },
          }));
          ShowNotification(
            viVN.Errors[err && err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };

  const handleChangeFileDescription = (event, optionAddRow, idFile, item) => {
    const { name, files } = event.target;
    if (files[0] && optionAddRow === 1) {
      if (!requireMetadata[idFile]?.recordsFileTypeName) {
        ShowNotification(
          viVN.Errors.NameRecordsFile,
          NotificationMessageType.Warning
        );
        return;
      }
      if (!requireMetadata[idFile]?.recordsFileNo) {
        ShowNotification(
          viVN.Errors.RecordsFileNo,
          NotificationMessageType.Warning
        );
        return;
      }
      if (!requireMetadata[idFile]?.governmentId) {
        ShowNotification(
          viVN.Errors.GovernmentId,
          NotificationMessageType.Warning
        );
        return;
      }
      UploadFileStream(
        {
          file: files[0],
          recordsId: recordsId,
          recordsFileTypeId: item?.id,
          recordsFileKindId: item?.id,
          recordsFileTypeName: requireMetadata[idFile]?.recordsFileTypeName,
        },
        {},
        requireMetadata[idFile]
      )
        .then((res) => {
          if (res?.content?.status) {
            ShowNotification(
              viVN.Success.UploadFileSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          ShowNotification(
            viVN.Errors[err && err.errorType],
            NotificationMessageType.Error
          );
        });
    }
    if (files[0] && optionAddRow === 0) {
      setListFile((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          file: files[0],
          name: files[0]?.name,
          uploadProgress: 0,
        },
      }));
    }
  };
  const handleCheckUpdatefile = (event, check, optionAddRow, idFile, item) => {
    if (check) {
      handleChangeFileDescription(event, optionAddRow, idFile, item);
    } else {
      handleChangeFileContent(event, optionAddRow, idFile, item);
    }
  };
  const handleChangeUpdatefile = (
    idRecordsFile,
    documentUploadId,
    recordsFileKindId,
    recordsFileTypeName,
    recordsFileTypeId,
    itemId
  ) => {
    if (!listFile) {
      ShowNotification(viVN.Errors.FileEmpty, NotificationMessageType.Warning);
      return;
    }
    if (!requireMetadata[itemId].recordsFileNo) {
      ShowNotification(
        viVN.Errors.RecordsFileNo,
        NotificationMessageType.Warning
      );
      return;
    }
    if (!requireMetadata[itemId].governmentId) {
      ShowNotification(
        viVN.Errors.GovernmentId,
        NotificationMessageType.Warning
      );
      return;
    }
    if (idRecordsFile === 0) {
      UploadFileStream(
        {
          file: listFile[itemId]?.file,
          recordsId: recordsId,
          recordsFileKindId: recordsFileKindId,
          recordsFileTypeId: recordsFileTypeId,
          recordsFileTypeName: recordsFileTypeName,
        },
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            setListFile((prevState) => ({
              ...prevState,
              [listFile.name]: {
                ...prevState[listFile.name],
                
                uploadProgress: (loaded / total) * 100,
              },
            }));
          },
        },
        requireMetadata[itemId]
      )
        .then((res) => {
          if (res?.content?.status) {
            ShowNotification(
              viVN.Success.UploadFileSuccess,
              NotificationMessageType.Success
            );
          }

          setListFile((prevState) => ({
            ...prevState,
            [listFile.name]: {
              name: listFile.name,
              recordsFileId: res?.content?.recordsFileId ,
              preview: `${apiConfig.api}${res?.content?.filePreview}`,
              uploadProgress: 0,
            },
          }));
        })
        .catch((err) => {
          ShowNotification(
            viVN.Errors[err && err.errorType],
            NotificationMessageType.Error
          );
        });
    } else {
      UpdateRecordFile(
        {
          id: idRecordsFile,
          recordsFileKindId: recordsFileKindId,
          recordsFileTypeName: recordsFileTypeName,
          documentUploadId: idRecordsFile,
          recordsId: recordsId,
          recordsFileTypeId: recordsFileTypeId,
        },
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            setListFile((prevState) => ({
              ...prevState,
              [listFile.name]: {
                ...prevState[listFile.name],
                uploadProgress: (loaded / total) * 100,
              },
            }));
          },
        },
        requireMetadata[itemId]
      )
        .then((res) => {
          if (res?.content?.status) {
            ShowNotification(
              viVN.Success.UploadFileSuccess,
              NotificationMessageType.Success
            );
          }

          setListFile((prevState) => ({
            ...prevState,
            [listFile.name]: {
              name: listFile.name,
              recordsFileId: res?.content?.recordsFileId ,
              preview: `${apiConfig.api}${res?.content?.filePreview}`,
              uploadProgress: 0,
            },
          }));
        })
        .catch((err) => {
          ShowNotification(
            viVN.Errors[err && err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };
    const handleChangeAddorUpdateAddFile = (
    idRecordsFile,
    documentUploadId,
    recordsFileKindId,
    recordsFileTypeName,
    recordsFileTypeId,
    idItem
  ) => {
    if (!addRowFile) {
      ShowNotification(viVN.Errors.FileEmpty, NotificationMessageType.Warning);
      return;
    }
    if (!requireMetadata[idItem]?.recordsFileTypeName) {
      ShowNotification(
        viVN.Errors.NameRecordsFile,
        NotificationMessageType.Warning
      );
      return;
    }
    if (!requireMetadata[idItem].recordsFileNo) {
      ShowNotification(
        viVN.Errors.RecordsFileNo,
        NotificationMessageType.Warning
      );
      return;
    }
    if (!requireMetadata[idItem].governmentId) {
      ShowNotification(
        viVN.Errors.GovernmentId,
        NotificationMessageType.Warning
      );
      return;
    }
    if (idRecordsFile === 0) {
      UploadFileStream(
        {
          file: addRowFile[idItem]?.file,
          recordsId: recordsId,
          recordsFileKindId: recordsFileKindId,
          // recordsFileTypeId: recordsFileTypeId,
          recordsFileTypeName: requireMetadata[idItem].recordsFileTypeName,
        },
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            setAddRowFile((prevState) => ({
              ...prevState,
              [idItem]: {
                ...prevState[idItem],
                uploadProgress: (loaded / total) * 100,
              },
            }));
          },
        },
        requireMetadata[idItem]
      )
        .then((res) => {
          if (res?.content?.status) {
            ShowNotification(
              viVN.Success.UploadFileSuccess,
              NotificationMessageType.Success
            );
          }
          setAddRowFile((prevState) => ({
            ...prevState,
            [idItem]: {
              ...prevState[idItem],
              idRecordsFile: res?.content?.recordsFileId,
              name: addRowFile[idItem].name,
              preview: `${apiConfig.api}${res?.content?.filePreview}`,
              uploadProgress: 0,
            },
          }));
        })
        .catch((err) => {
          ShowNotification(
            viVN.Errors[err && err.errorType],
            NotificationMessageType.Error
          );
        });
    } else {
      UpdateRecordFile(
        {
          id: addRowFile[idItem]?.idRecordsFile,
          recordsFileKindId: recordsFileKindId,
          recordsFileTypeName: recordsFileTypeName,
          documentUploadId: documentUploadId,
          recordsId: recordsId,
          // recordsFileTypeId: recordsFileTypeId,
        },
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            setAddRowFile((prevState) => ({
              ...prevState,
              [idItem]: {
                ...prevState[idItem],
                uploadProgress: (loaded / total) * 100,
              },
            }));
          },
        },
        requireMetadata[idItem]
      )
        .then((res) => {
          if (res?.content?.status) {
            ShowNotification(
              viVN.Success.UploadFileSuccess,
              NotificationMessageType.Success
            );
          }

          setAddRowFile((prevState) => ({
            ...prevState,
            [idItem]: {
              ...prevState[idItem],
              idRecordsFile: res?.content?.recordsFileId,
              name: addRowFile[idItem].name,
              preview: `${apiConfig.api}${res?.content?.filePreview}`,
              uploadProgress: 0,
            },
          }));
        })
        .catch((err) => {
          ShowNotification(
            viVN.Errors[err && err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };
  const handleRemoveFile = (field, id) => {
    setListFile({
      ...listFile,
      [field]: null,
    });
    if(id){
      DeteleRecordFileId(id)
    }
  };
  const handleRemoveAddRowFile = (field, id) => {
    setAddRowFile({
      ...addRowFile,
      [field]: null,
    });
    if(id){
      DeteleRecordFileId(id)
    }
  };
  const onSubmit = () => {
    if (!isLastStep) {
      setActiveStep(activeStep + 1);
    } else {
      UpdateStep({
        recordsId: recordsId,
        step: recordsTypeId,
      })
        .then((response) => {
          if (response?.content?.status) {
            ShowNotification(
              viVN.Success.UpdateSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          ShowNotification(
            viVN.Errors[err && err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };

  useEffect(() => {
    async function onGetListRecordsFile() {
   await  GetListRecordsFile({
      recordsId: recordsId,
      recordsTypeId: recordsTypeId,
    })
      .then((res) => {
        let newInputFile = {};
        let cmText = {};
        let cmDoc = {};
        for (let i = 0; i < res?.content?.items?.length; i++) {
          if(res?.content?.items[i]?.recordsFileKindId) {
            console.log("res?.content?.items[i]?.recordsFileKindId", res?.content?.items[i]);
            newInputFile = {
              ...newInputFile,
              [res?.content?.items[i]?.recordsFileKindId]: {
                idRecordsFile: res?.content?.items[i]?.id,
                recordsFileTypeId: res?.content?.items[i]?.recordsFileTypeId,
                documentUploadId: res?.content?.items[i]?.documentUploadId,
                recordsFileKindId: res?.content?.items[i]?.recordsFileKindId,
                recordsFileTypeName: res?.content?.items[i]?.recordsFileTypeName,
                governmentId: res?.content?.items[i]?.governmentId,
                recordsFileDate: res?.content?.items[i]?.recordsFileDate,
                recordsFileNo: res?.content?.items[i]?.recordsFileNo,
                recordsFileDescription: res?.content?.items[i]?.recordsFileDescription,
                name: res?.content?.items[i]?.files?.fileName,
                preview: handlePreviewFile(
                  res?.content?.items[i]?.files?.filePreview
                ),
              },
            };
          } else {
            newInputFile = {
              ...newInputFile,
              [res?.content?.items[i]?.id]: {
                idRecordsFile: res?.content?.items[i]?.id,
                recordsFileTypeId: res?.content?.items[i]?.recordsFileTypeId,
                documentUploadId: res?.content?.items[i]?.documentUploadId,
                recordsFileKindId: res?.content?.items[i]?.recordsFileKindId,
                recordsFileTypeName: res?.content?.items[i]?.recordsFileTypeName,
                governmentId: res?.content?.items[i]?.governmentId,
                recordsFileDate: res?.content?.items[i]?.recordsFileDate,
                recordsFileNo: res?.content?.items[i]?.recordsFileNo,
                recordsFileDescription: res?.content?.items[i]?.recordsFileDescription,
                name: res?.content?.items[i]?.files?.fileName,
                preview: handlePreviewFile(
                  res?.content?.items[i]?.files?.filePreview
                ),
              },
            };
           
          }
          if(!res?.content?.items[i]?.recordsFileNo && !res?.content?.items[i]?.governmentId && !res?.content?.items[i]?.recordsFileDate && !res?.content?.items[i]?.recordsFileDescription) {
            if(res?.content?.items[i]?.recordsFileKindId) {
              cmDoc = {
                ...cmDoc,
                [res?.content?.items[i]?.recordsFileKindId]: [
                  res?.content?.items[i]?.recordsFileKindId
                ]
              }
              // cmDoc.push(res?.content?.items[i]?.recordsFileKindId)
            }
           
          }else{
            // if(res?.content?.items[i]?.recordsFileKindId) {
            //   cmText.push(res?.content?.items[i]?.recordsFileKindId)
            // }
            cmText = {
              ...cmText,
              [res?.content?.items[i]?.recordsFileKindId]: [
                res?.content?.items[i]?.recordsFileKindId
              ]
            }
          }
        }
        reset();
        setListFile(newInputFile);
        setAddRowFile(newInputFile);
        setRequireMetadata(newInputFile);
        setComponentDocument(cmDoc);
        setComponentText(cmText);
      })
      .catch((err) => {
        ShowNotification(
          viVN.Errors[err && err.errorType],
          NotificationMessageType.Error
        );
      });
    }
    onGetListRecordsFile()
  }, [recordsId, recordsTypeId, reset]);

  useEffect(() => {
    setActiveStep(1);
    let steps = [];

    if (recordData.length) {
      let newData = {};
      for (let i = 0; i < recordData.length; i++) {
        const step = recordData[i]?.wizardStep;

        if (!steps.some((item) => item?.key === step)) {
          steps = [
            ...steps,
            {
              key: step,
              label: recordData[i]?.wizardStepName,
            },
          ];
        }

        const object = {
          label: recordData[i]?.name,
          id: recordData[i]?.id,
          field: [],
          required: false,
          allowAddNew: recordData[i]?.allowAddNew,
          recordsFileKindId: recordData[i]?.id,
          recordsFileTypeId: recordData[i]?.recordsTypeId,
        };
       
        for (let j = 0; j < recordData[i]?.recordsFileTypes?.length; j++) {
          object.field.push({
            label: recordData[i]?.recordsFileTypes[j]?.name,
            name: recordData[i]?.recordsFileTypes[j]?.id,
            isRequireMetadata: recordData[i]?.recordsFileTypes[j]?.isRequireMetadata,
            recordsFileKindId: recordData[i]?.id,
            recordsFileTypeId: recordData[i]?.recordsFileTypes[j]?.id,
            recordsFileTypeName: recordData[i]?.recordsFileTypes[j]?.name,
          });
        }
        if (newData[`${step}`]?.length) {
          newData[`${step}`].push(object);
        } else {
          newData[`${step}`] = [object];
        }
      }
      const addStepLast = {
        key: steps.length + 1,
        label: "Kiểm tra",
      };
      if (steps.length > 1) {
        steps.push(addStepLast);
        newData[`${steps.length}`] = [];
      }
      setSteps(steps);

      setListInput(newData);
    }
  }, [recordData]);
  useEffect(() => {
    async function onGetLookupGovernment() {
      await GetLookupGovernment().then(
        (res) => {
          if (res && res.content) {
            setRecordsGovernment(res.content);
          }
        },
        (err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    }
    onGetLookupGovernment();
  }, []);
  return (
    <div className="quy-hoach-page">
      {steps.length > 1 && (
        <Stepper activeStep={activeStep - 1} alternativeLabel>
          {steps.map(({ key, label }) => (
            <Step key={key}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {listInput[`${activeStep}`]?.map((item, index) => (
          <UploadFileComponent
            index={(listInput[`${activeStep - 1}`]?.length || 0) + index + 1}
            item={item}
            listFile={listFile}
            onChange={handleCheckUpdatefile}
            onRemove={handleRemoveFile}
            onRemoveAddRowFile={handleRemoveAddRowFile}
            key={index}
            register={register}
            errors={errors}
            onAddorUpdate={handleChangeAddorUpdateAddFile}
            clearErrors={clearErrors}
            recordsGovernment={recordsGovernment}
            requireMetadata={requireMetadata}
            setRequireMetadata={setRequireMetadata}
            optionAddRow={optionAddRow}
            setOptionAddRow={setOptionAddRow}
            addRowFile={addRowFile}
            onUpdate={handleChangeUpdatefile}
            setAddRowFile={setAddRowFile}
            handleChangeMeta={handleChangeMeta}
            handleChangeFileAddRow={handleChangeFileAddRow}
            reomveFileAddRow={reomveFileAddRow}
            componentDocument={componentDocument}
            setComponentDocument={setComponentDocument}
            componentText={componentText}
            setComponentText={setComponentText}
          />
        ))}
        {activeStep === steps.length && steps.length > 1 && (
          <div style={{ textAlign: "center" }} className="mt-5">
            <div className="icons">
              <IconSend />
            </div>
            <div className="text">
              Hồ sơ đã được gửi tới chuyên viên phụ trách của Vụ quản lý quy
              hoạch, <br />
              quy trình kiểm tra sẽ mất thời gian 3 – 5 ngày làm việc.
            </div>
          </div>
        )}
        <Box mt={6} textAlign="center" columnGap={20}>
          {steps.length > 1 && activeStep > 1 && (
            <Button onClick={handleChangeStep(1)} className="button-deactivate">
              Quay lại
            </Button>
          )}
          {(steps.length === 1 || isLastStep) && (
            <Button
              type="submit"
              className="button-active"
              disabled={Object.values(listFile || {}).some(
                (item) => item?.uploadProgress
              )}
            >
              {!isFinalStep ? "Hoàn thành" : "Nộp hồ sơ"}
            </Button>
          )}
          {steps.length > 1 && !isLastStep && (
            <Button
              type="submit"
              className="button-active"
              disabled={Object.values(listFile || {}).some(
                (item) => item?.uploadProgress
              )}
            >
              Tiếp tục
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
}

export default connect(null, null)(QuyHoach);

const IconSend = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.75 17H51.75C53.5375 17 55 18.4062 55 20.125V38.875C55 40.5938 53.5375 42 51.75 42H25.75C23.9625 42 22.5 40.5938 22.5 38.875V20.125C22.5 18.4062 23.9625 17 25.75 17Z"
        stroke="#27B733"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.5 19L38.75 32L23 19"
        stroke="#27B733"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 39.5H15"
        stroke="#27B733"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 29.5H15"
        stroke="#27B733"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 19.5H15"
        stroke="#27B733"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
