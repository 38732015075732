import { Box, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import "./change-password.scss"
import * as acountAction from "./../../../redux/store/account/account.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "./../../../languages/vi-VN.json";
import { NotificationMessageType } from "../../../utils/configuration";
function ChangePassword(props) {
    const [password, setPassword] = useState({});
    const onSummit = () => {
        if(!password.newPassword || !password.newPasswordC || !password.currentPassword){
            ShowNotification(
                viVN.Errors.PasswordChangeNull,
                NotificationMessageType.Error
              );
              return;
        }
        if(password.newPassword !== password.newPasswordC){
            ShowNotification(
                viVN.Errors.PasswordChange,
                NotificationMessageType.Error
              );
              return;
        }
        acountAction
        .ChangePassword(password)
        .then((res) => {
          if (res && res.content && res.content) {
              setPassword({})
            ShowNotification(
              viVN.Success.ResetPasswordSuccess,
              NotificationMessageType.Success
            );
          } 
        })
        .catch((err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        });
    }
    const hanldeChange = (e) => {
        const { name, value } = e.target;
        setPassword({ ...password,[name]: value})

    }
    return (
        <div className="change-password-wrapper">
            <Box className="item-line" display="flex">
                <div className="label">Mật khẩu hiện tại</div>
                <TextField  type="password" className="input" id="outlined-basic" name="currentPassword" value={password?.currentPassword} variant="outlined" onChange={hanldeChange} />
            </Box>
            <Box className="item-line" display="flex">
                <div className="label">Mật khẩu mới</div>
                <TextField type="password" className="input" id="outlined-basic" name="newPassword" value={password?.newPassword} variant="outlined" onChange={hanldeChange} />
            </Box>
            <Box className="item-line" display="flex">
                <div className="label">Nhập lại mật khẩu mới</div>
                <TextField type="password" className="input" id="outlined-basic" name="newPasswordC" value={password?.newPasswordC} variant="outlined" onChange={hanldeChange} />
            </Box>
            <Box className="item-line" display="flex">
                <Button  className="button-active" onClick={onSummit}>Cập nhật</Button>
                </Box>
        </div>
    );
}

// const mapStateToProps = (state) => ({
//     loadingQueue: state.loadingState.loadingQueue,
// });

export default connect(null, null)(ChangePassword);
