import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const CreateProject = (data) => {
    return service
        .post(ApiUrl.CreateProject, data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListProject = (data) => {
    const params = new URLSearchParams();
    console.log("getlist", data);
  params.append("pageIndex", data.pageIndex);
    params.append("pageSize", data.pageSize);
    params.append("isActive", 1);
    data.governmentId && params.append("governmentId", data.governmentId);
    data.provinceId && params.append("provinceId", data.provinceId);
    data.projectFieldId && params.append("projectFieldId", data.projectFieldId);
    data.projectTypeId && params.append("projectTypeId", data.projectTypeId);
    data.projectStatusId && params.append("projectStatusId", data.projectStatusId);
    data.keyword && params.append("keyword", data.keyword);

  return service
      .get(ApiUrl.GetListProject, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const GetDetailProject = (id) => {
  let requestUrl = ApiUrl.GetDetailProject + '/' +id;
  return service
    .get(requestUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const InitState = {

};

export default function ProjectReducer(state = InitState, action) {
    switch (action.type) {
        default:
            return state;
    }
}