import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import moment from 'moment';
const service = new Service();

export const GetRecordsId = (data) => {
    return service.get(ApiUrl.GetRecordsId).then((res) => {return res}).catch((err) => { throw err });
}
export const GetAllLogHistory = (data) => {
    const params = new URLSearchParams();
    params.append("pageIndex", data.pageIndex);
    params.append("pageSize", data.pageSize);
    params.append("rowId", data.rowId);
    return service.get(ApiUrl.GetAllLogHistory, params).then((res) => {return res}).catch((err) => { throw err });
}
export const GetLookupGovernment = (data) => {
    return service.get(ApiUrl.GetLookupGovernment).then((res) => {return res}).catch((err) => { throw err });
}
export const GetListRecordsTypeByPlanningKind = (data) => {
    return service.get(ApiUrl.GetListRecordsTypeByPlanningKind).then((res) => {return res}).catch((err) => { throw err });
}
export const GetRecordsType = (data) => {
    const params = new URLSearchParams();
    data?.recordsTypeId && params.append("RecordsTypeId", data?.recordsTypeId);
    return service.get(ApiUrl.GetRecordsType, params).then((res) => {return res}).catch((err) => { throw err });
}

export const GetListRecordsFile = (data) => {
    const params = new URLSearchParams();
    params.append("RecordsId", data.recordsId);
    params.append("RecordsTypeId", data.recordsTypeId);

    return service.get(ApiUrl.GetListRecordsFile, params).then((res) => {return res}).catch((err) => { throw err });
}


export const UploadFileStream = (data, config, paramProp) => {
    // let param =''
    // if(paramProp?.recordsFileNo && paramProp?.recordsFileDate && paramProp?.governmentId) {
    //   param =`?recordsFileNo=${paramProp.recordsFileNo}&recordsFileDate=${moment(new Date(paramProp.recordsFileDate)).format('DD/MM/YYYY')}&governmentId=${paramProp.governmentId}`
    // }
    const formData = new FormData();
    data && data?.file && formData.append("File", data.file);
    data && data?.recordsId && formData.append("RecordsId", data.recordsId);
    data && data?.recordsFileKindId && formData.append("RecordsFileKindId", data.recordsFileKindId);
    data && data?.recordsFileTypeId && formData.append("RecordsFileTypeId", data.recordsFileTypeId);
    data && data?.recordsFileTypeName && formData.append("RecordsFileTypeName", data.recordsFileTypeName);
    paramProp && paramProp?.recordsFileNo && formData.append("RecordsFileNo", paramProp.recordsFileNo);
    paramProp && paramProp?.recordsFileDate && formData.append("RecordsFileDate", moment(new Date(paramProp.recordsFileDate)).format('yyyy-MM-DD'));
    paramProp && paramProp?.governmentId && formData.append("GovernmentId", paramProp.governmentId);
    paramProp && paramProp?.recordsFileDescription && formData.append("RecordsFileDescription", paramProp.recordsFileDescription);
    return service
      .post(ApiUrl.UploadFileStream, formData, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};
export const UpdateRecordFile = (data, config, paramProp) => {
    const formData = new FormData();
    data && data?.recordsId && formData.append("RecordsId", data.recordsId);
    data && data?.id && formData.append("Id", data.id);
    data && data?.recordsFileKindId && formData.append("RecordsFileKindId", data.recordsFileKindId);
    data && data?.documentUploadId && formData.append("DocumentUploadId", data.documentUploadId);
    data && data?.recordsFileTypeId && formData.append("RecordsFileTypeId", data.recordsFileTypeId);
    data && data?.recordsFileTypeName && formData.append("RecordsFileTypeName", data.recordsFileTypeName);
    paramProp && paramProp?.recordsFileNo && formData.append("RecordsFileNo", paramProp.recordsFileNo);
    paramProp && paramProp?.recordsFileDate && formData.append("RecordsFileDate", moment(new Date(paramProp.recordsFileDate)).format('yyyy-MM-DD'));
    paramProp && paramProp?.governmentId && formData.append("GovernmentId", paramProp.governmentId);
    paramProp && paramProp?.recordsFileDescription && formData.append("RecordsFileDescription", paramProp.recordsFileDescription);
   
    return service
      .post(ApiUrl.UpdateRecordFile, formData, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};
export const UpdateStep = (data = {}) => {
    return service
        .post(`${ApiUrl.UpdateStep}/${data.recordsId}/${data.step}`)
        .then((res) => {
        return res;
        })
        .catch((err) => {
        throw err;
        });
};

export const CreateRecords = (data) => {
    return service.post(ApiUrl.CreateRecords, data).then((res) => {return res}).catch((err) => { throw err });
}
export const UpdateRecords = (data) => {
    return service.post(ApiUrl.UpdateRecords, data).then((res) => {return res}).catch((err) => { throw err });
}
export const GetRecordsDetail = (id) => {
    return service.get(ApiUrl.GetRecordsDetail + `/${id}`).then((res) => {return res}).catch((err) => { throw err });
}
export const GetLookupProvince = () => {
    return service.get(ApiUrl.GetLookupProvince).then((res) => {return res}).catch((err) => { throw err });
}
export const GetLookupPlanningCategoryByPlanningKind = (id) => {
    return service.get(ApiUrl.GetLookupPlanningCategoryByPlanningKind + id).then((res) => {return res}).catch((err) => { throw err });
}
export const GetLookupPlanningKind = () => {
    return service.get(ApiUrl.GetLookupPlanningKind).then((res) => {return res}).catch((err) => { throw err });
}
export const DeteleRecord = (id) => {
    return service.post(ApiUrl.DeleteRecord + id).then((res) => {return res}).catch((err) => { throw err });
}

export const DeteleRecordFileId = (id) => {
    return service.post(ApiUrl.DeteleRecordFileId + `/${id}`).then((res) => {return res}).catch((err) => { throw err });
}
