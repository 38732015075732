/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./intro.scss";
import * as appActions from "../../core/app.store";
import Service from "../../api/api-service";
import { ApiUrl } from "../../api/api-url";
const service = new Service();

function Intro(props) {
  const { showLoading } = props;
  const [intro,setIntro] = useState([]);

  useEffect(() => {
      getIntro();
  },[]);

  const getIntro = () => {
      showLoading(true);
      return new Promise((resolve,reject) => {
          return service
              .get(ApiUrl.HomeIntro)
              .then((res) => {
                  setIntro(res)
                  showLoading(false);
                  resolve(res);
              })
              .catch((err) => {
                  throw err;
              })
              .finally(() => {
                  showLoading(false);
              });

      })
  }

  return (
    <div className="container">
        {intro && intro.content && (
            <div className="intro-page row">
                <div className="intro-title col-12">
                    {intro.content.title}
                </div>
                <div className="intro-content col-12">
                    <p className="new-desc" dangerouslySetInnerHTML={{ __html: intro.content.content }}></p>
                </div>
            </div>
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Intro));
