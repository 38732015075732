/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as userInfoAction from "./../../../redux/store/user-info/user-info.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "./../../../languages/vi-VN.json";
import { NotificationMessageType } from "../../../utils/configuration";
import "./create-project.scss";
import ProjectInfo from "../../../modules/user-info/project-info/project-info.view";
const startYearData = [
  {
    value: 2011,
    text: "2011",
  },
  {
    value: 2012,
    text: "2012",
  },
  {
    value: 2013,
    text: "2013",
  },
  {
    value: 2014,
    text: "2014",
  },
  {
    value: 2015,
    text: "2015",
  },
  {
    value: 2016,
    text: "2016",
  },
  {
    value: 2017,
    text: "2017",
  },
  {
    value: 2018,
    text: "2018",
  },
  {
    value: 2019,
    text: "2019",
  },
  {
    value: 2020,
    text: "2020",
  },
  {
    value: 2021,
    text: "2021",
  },
];
function CreateProjectModal(props) {
  const {
    modalCreateProject,
    handleCloseModalCreateProject,
  } = props;
  
  return (
    <div>
      <Modal
        show={modalCreateProject}
        onHide={handleCloseModalCreateProject}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        className="modal-user-info-create-project"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Tạo mới quy hoạch</h5>
          </Modal.Title>
        </Modal.Header>
        <form
        // onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Body>
              <ProjectInfo isNew={true}></ProjectInfo>
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
}

export default CreateProjectModal;
