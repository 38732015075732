/* eslint-disable react-hooks/exhaustive-deps */
import React  from "react";
import UrlCollect from "../../common/url-collect";

function RelatedDocument(props) {
    const { currentPlanningId,listPlanning } = props;

    const getDetailUrl = (planning) => {
        let url = '';
        if(planning.planningTypeId === 1000){
            url = UrlCollect.PlanningDocument + "/" + planning.id;
        }else{
            url = UrlCollect.DetailedPlanning + "/" + planning.id;
        }
        return url;
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="header-widget">
                    <div className="title">
                        Hồ sơ liên quan
                    </div>
                </div>
                <div className="related-info-box">
                    {
                        listPlanning && listPlanning.length > 0 && (
                            listPlanning.map((planning, index) => (
                                planning.id != currentPlanningId && (
                                    <div key={index}>
                                        <div className="planning-title">
                                            <a href={getDetailUrl(planning)}>{planning.name}</a>
                                        </div>
                                    </div>
                                )
                             )
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default (RelatedDocument);
