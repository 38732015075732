/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { Fill, Stroke, Style } from "ol/style";
import VectorImageLayer from "ol/layer/VectorImage";
import Select from "react-select";
import $ from "jquery";

//--- Service
import * as MapToolsPanelsStore from "../../../redux/store/open-layer/map-tools-panels.store";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import * as OpenlayerStore from "../../../redux/store/open-layer/open-layer.store";
import * as MapToolAction from "../../../redux/store/map-tool/map-tool.store";

//--- Configs
import ViLanguages from "./../../../languages/vi";
import { Default_Feature_Style } from "../../openlayers-map/config/config";

//--- Style
import "./filter-infomation.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import GeometryType from "ol/geom/GeometryType";
import { Container, Form, Col, Row } from "react-bootstrap";
import * as config from "../../../utils/configuration";
import { NotificationMessageType } from "../../../utils/configuration";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";

const LanguageCollects = ViLanguages;
const TYPE_DISPLAY = {
  CHAR: "text",
  DROPDOWN: "dropdown",
  NUMBER: "number",
};
const CONDITION_TYPE = {
  LON_HON: "GREATER",
  NHO_HON: "LESS",
  BANG: "=",
  TRONG_KHOANG: "BETWEEN",
};

function RenderFilterInformationPopup(props) {
  const highlightStyle = new Style({
    stroke: new Stroke({
      color: "rgba(0,230,241,1)",
      width: 3,
    }),
    fill: new Fill({
      color: "rgba(223,16, 188,1)",
    }),
  });

  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "onBlur",
  });

  const [objectSearch, setObjectSearch] = useState("");
  const [isSearchWithAttribute, setIsSearchWithAttribute] = useState(false);
  const [
    attributesOfSelectedObject,
    setAttributesOfSelectedObject,
  ] = useState();
  const [listFeature, setListFeature] = useState([]);
  const [listLayerActive, setListLayerActive] = useState([]);
  const [listLayerOption, setListLayerOption] = useState([]);
  const [tableName, setTableName] = useState("")
  const [outPut, setOutPut] = useState([]);
  const [layerChoose, setLayerChoose] = useState();
  const [features, setFeatures] = useState([]);

  const [currentDrawMode, setCurrentDrawMode] = useState('');
  const [radiusValue, setRadiusValue] = useState("");


  const planningId = props.planningId;
  const [layerId, setLayerId] = useState(0);

  useEffect(() => {
    const ListButtonSearchType = Array.from(
      document.getElementsByClassName("search-by-type-button")
    );
    ListButtonSearchType.map((Button, index) => {
      Button.addEventListener("click", () => handleAddClassState(index));
    });

    const ListButtonTitle = Array.from(
      document.getElementsByClassName("custom-select-title-id")
    );
    ListButtonTitle.map((Button, index) => {
      Button.addEventListener("click", () =>
        handleToggleClassStateTitleButton(index)
      );
    });
  }, []);

  useEffect(() => {
    let listLayer = [];
    let formatList = [];
    let optionList = [];
    if(props.listGroupLayer){
      listLayer = getListLayerActive(listLayer,props.listGroupLayer)
      if(listLayer && Array.isArray(listLayer) && listLayer.length >0){
        listLayer.map((layer) => {
          layer.table = $.trim(layer.table);
          layer.name = $.trim(layer.name);
          if(layer.table && layer.name){
            formatList.push(layer);
            optionList.push({'id':layer.id,'name':layer.name,'table':layer.table})
          }
        })
      }
    }

    setListLayerOption(optionList);
    setListLayerActive(formatList);

  }, [props.listGroupLayer]);

  const getListLayerActive = (listLayer,listGroupLayer) => {
    listGroupLayer.map((item) => {
      listLayer.push(...item.layer_settings);
    });

    for(let i=0; i< listGroupLayer.length; i++){
      if(listGroupLayer[i].layer_settings && Array.isArray(listGroupLayer[i].layer_settings) && listGroupLayer[i].layer_settings.length >0){
        listLayer.push(listGroupLayer[i].layer_settings);
      }
      if(listGroupLayer[i].children && Array.isArray(listGroupLayer[i].children) && listGroupLayer[i].children.length >0){
        listLayer = getListLayerActive(listLayer,listGroupLayer[i].children);
      }
    }
    return listLayer;
  }

  useEffect(() => {
    if (layerChoose) {
      props.listLayer.map((layer) => {
        if (layer.table === layerChoose.table) {
          props.SaveHeaderForTable(
            layer.filter_name ? layer.filter_name.out : []
          );
        }
      });
    }
  }, [props.listLayer, layerChoose]);
  useEffect(() => {
    const listColumns = attributesOfSelectedObject && attributesOfSelectedObject.length > 0 &&
      attributesOfSelectedObject.filter((item) => item.type_display && item.type_display === "dropdown").map(item => item.col)
    if (layerChoose && listColumns) {
      GetDropDown(tableName, listColumns);
    }
  }, [layerChoose]);

  const GetDropDown = async (_tableName, listColums) => {
    const data = {
      tableName: _tableName ? _tableName : '',
      columnNames: [...listColums]
    }
    await MapToolAction.GetDropDown(data).then((res) => {
      if (res && res.content) {
        setFeatures(res && res.content);
      }
    }).catch((err) => {
      ShowNotification(
        viVN.Errors[err && err.errorType],
        NotificationMessageType.Errors,
      );
    })
  }

  const handleClickDrawFilter = (event, drawType) => {
    const _buttonElement = event.target;
    const _parentNode = _buttonElement.parentNode;
    if (_buttonElement.classList.contains('active') || _parentNode.classList.contains('active')) {
      props.openLayerController.deleteFilterDrawnVectorLayer();
      setCurrentDrawMode('');
    } else {
      setCurrentDrawMode(drawType);
      props.openLayerController.filterDrawnVectorLayer(drawType)
    }
  }

  function handleToggleClassStateTitleButton(indexActive) {
    const ListButtonTitle = Array.from(
      document.getElementsByClassName("custom-select-title-id")
    );
    ListButtonTitle.map((Button, index) => {
      if (index != indexActive) Button.classList.remove("active");
      else Button.classList.add("active");
    });
  }

  function handleAddClassState(indexActive) {
    const ListButtonSearchType = Array.from(
      document.getElementsByClassName("search-by-type-button")
    );
    ListButtonSearchType.map((Button, index) => {
      if (index === indexActive) Button.classList.add("active");
      else Button.classList.remove("active");
    });
  }
  
  function handleChangeObjectSearch(option) {

    if(option && option.id && option.table){
      option.id = $.trim(option.id);
      option.table = $.trim(option.table);
      setTableName(option.table)
      setLayerId(option.id);
      const _object = listLayerActive.find((item) => item.table == option.table);
      setObjectSearch(option.table);
      if (_object) {
        handlePlanningSearch(_object,listLayerActive);
        setLayerChoose(_object);
        if (_object.filter_name) {
          setAttributesOfSelectedObject(_object.filter_name.in || []);
          setOutPut(_object.filter_name.out || []);
        }
      }
    }else{
      clearData();
    }
  }

  function handlePlanningSearch(layer,itemList){
    const layerId = (layer && layer.id) || 0;
    if(itemList && Array.isArray(itemList) && itemList.length >0 && layerId >0){
      itemList.map((item) => {
        if(item && item.id && item.id >0 && item.id == layerId){
          item.is_check = true;
          $('#map-item-'+item.id).prop('checked',true);
        }else{
          item.is_check = false;
          $('#map-item-'+item.id).prop('checked',false);
        }
        props.openLayerController.toggleDisplayLayer(item);
      })
    }
  }

  const isValidData = (_data, _feature) => {
    let isValid = true;
    _data.forEach((item) => {
      if (!item.data || !isValid) return;

      if (item.type == TYPE_DISPLAY.NUMBER) {
        switch (item.condition_type) {
          case CONDITION_TYPE.LON_HON.toString():
            if (item.data < _feature.get(item.key)) break;
            isValid = false;
            break;
          case CONDITION_TYPE.NHO_HON.toString():
            if (item.data > _feature.get(item.key)) break;
            isValid = false;
            break;
          case CONDITION_TYPE.BANG.toString():
            if (item.data == _feature.get(item.key)) break;
            isValid = false;
            break;
          case CONDITION_TYPE.TRONG_KHOANG.toString():
            const _dataArr = item.data.split(" ");
            if (!_dataArr || _dataArr.length !== 2) {
              isValid = false;
              break;
            }
            if (
              _dataArr[0] <= _feature.get(item.key) &&
              _dataArr[1] >= _feature.get(item.key)
            ) break;
            isValid = false;
            break;
          default:
            isValid = false;
            break;
        }
      } else if (_feature.get(item.key) != item.data) {
        isValid = false;
      }
    });

    return isValid;
  };

  async function onSubmit(_data) {
    if (!_data) return;
    if(!layerChoose) {
      ShowNotification(
        "Bạn phải chọn đối tượng cần tìm kiếm",
        NotificationMessageType.Warning,
        config.NotificationPosition.Center
      );
    }
    if (listFeature.length !== 0) {
      listFeature.map((features) => {
        features.setStyle(Default_Feature_Style(features));
      })
    }
    const data = parseStringToObjectModel(_data);
    let _searchType = "";
    let _r = 0;
    let _shp = "";
    let _value = props.openLayerController.getCurrentDrawFeature();
    if (_value) {
      switch (currentDrawMode) {
        case "LineString":
          _searchType = "LineString";
          _shp = _value.coordinate;
          break;
        case "Polygon":
          _searchType = "Polygon";
          _shp = _value.coordinate;
          break;
        case "Circle":
          _searchType = "Circle"
          _shp = _value.coordinate;
          _r = _value.r
          break;
        case "Point":
          _searchType = "Point";
          _shp = _value.coordinate;
          _r = _value.r !== 0 ? _value.r : 0.001;
          break;
        case "Box":
          _searchType = "Box";
          _shp = _value.coordinate;
          break;

        default:
          break;
      }
    }
    let _requestBody = {
      tbName: tableName,
      values: JSON.stringify(data),
      lang: "vn",
      shp: _shp,
      r: _r,
      searchType: _searchType
    }
    const properties = [];
    const boundaries = [];

    try {
      const res = await MapToolAction.MapFilter(_requestBody);
      if (res && res.content) {
        let feature = JSON.parse(res.content);
        console.log('feature',feature);
        if (feature && feature.data) {
          const _data = feature.data;
          for (let i = 0; i <= _data.length; i++) {
            if (_data[i]?.geo) {
              boundaries.push(_data[i].geo);
              //delete _data[i].geo;
            }
            _data[i]?.geom && delete _data[i].geom;
            _data[i] && properties.push(_data[i]);

            console.log(_data[i])

          }
        }
      }
    } catch (error) {
      return;
    }
    let listFeatureSelected = [];
    props.SaveInfomationForTable({
      'data':properties,
      'planning_id':planningId,
      'layer_id':layerId,
      'table_name':tableName
    });
    props.openLayerController.functionHightLightPolygon(boundaries);
    props.SaveOpenlayerFeaturesSelected(listFeatureSelected);
    const btnRelativeResultTab = document.getElementById(
      "btnRelativeResultTab"
    );
    if (btnRelativeResultTab) {
      if (btnRelativeResultTab.classList.contains("collapsed"))
        btnRelativeResultTab.click();
    }
  }

  function clearData() {
    props.SaveInfomationForTable([]);
    setObjectSearch("");
    setLayerChoose();
    props.openLayerController.clearFeaturesSelected();
    setIsSearchWithAttribute(false);
    if (listFeature.length !== 0) {
      listFeature.map((features) => {
        features.setStyle(Default_Feature_Style(features));
      })
    }
  }

  function handleCloseFilterModal() {
    props.openLayerController.functionTurnOffFilterMode();
    clearData();
  }

  const renderAttributesOfSelectedObject = (_array = []) => {
    if (!_array || _array.length === 0) {
      return (
        <div className="text-center text-danger mt-1 mb-2">
          Không có thuộc tính cần tìm kiếm
        </div>
      );
    }
    const _className = `vector-image-layer-${(layerChoose && layerChoose.id) || ""
      }`;

    return _array.map((attribute) => (
      <RenderAttributeSelect
        key={`attribute_${attribute.col}`}
        data={attribute}
        features={features}
        getValues={getValues}
        setValue={setValue}
        register={register}
      />
    ));
  };

  const parseStringToObjectModel = (model) => {
    const dataAfterFilter = [];
    for (let item in model) {
      if (!model[item]) continue;
      try {
        let object = JSON.parse(model[item]);
        if (object && object.v) {
          dataAfterFilter.push(object);
        }
      } catch (error) {
        continue;
      }
    }
    return dataAfterFilter;
  };
  const showNotificationNotChooseLayer = () => {
    setIsSearchWithAttribute(!isSearchWithAttribute);
    if (!layerChoose) {
      ShowNotification(
        "Bạn phải chọn đối tượng cần tìm kiếm",
        NotificationMessageType.Warning,
        config.NotificationPosition.Center
      );
    }
  }

  if (!props.isDisplay) return null;

  return (
    <div
      id="right-panel-tool-filter-box"
      className={`position-absolute filter-information-popup-container ${props.classCustom}`}
    >
      <div className="position-relative w-100 h-100">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="body-group" id="accordionSearchContainer">
            <div className="row container-header-title">
              <h6
                data-toggle="collapse"
                data-target="#collapseSearchOption"
                aria-expanded="true"
                aria-controls="collapseSearchOption"
                className="search-title col-6 custom-select-title-id active"
              >
                {
                  LanguageCollects.planningMapView.filterInfomationPopup
                    .headTitle
                }
              </h6>

            </div>
            <div
              className="h-100 collapse show"
              id="collapseSearchOption"
              data-parent="#accordionSearchContainer"
            >
              <div className="form-group">
                <label htmlFor="select-filter-control">
                  {
                    LanguageCollects.planningMapView.filterInfomationPopup
                      .labelSelectSearch
                  }
                </label>
                {(listLayerOption && listLayerOption.length > 0) && (
                <Autocomplete
                    options={listLayerOption}
                    getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                    }
                    onChange={(event, newValue) => {
                      handleChangeObjectSearch(newValue);
                    }}

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="planningSearchId"
                            variant="outlined"
                            size="small"
                            placeholder="Nhập đối tượng tìm kiếm"
                        />
                    )}
                />
                )}
              </div>
              <div className="list-search-type">
                <button type="button" className={`btn search-by-type-button ${currentDrawMode === GeometryType.LINE_STRING && "active"}`}
                  onClick={(evt) => handleClickDrawFilter(evt, GeometryType.LINE_STRING)}>
                  <img
                    src={require("../../../assets/icon/lineString.svg")}
                    title="Tìm kiếm trên đường"
                    alt="Tìm kiếm trên đường"
                  />
                </button>
                <button type="button" className={`btn search-by-type-button ${currentDrawMode === GeometryType.POLYGON && "active"}`}
                  onClick={(evt) => handleClickDrawFilter(evt, GeometryType.POLYGON)}>
                  <img
                    src={require("../../../assets/icon/polygon.svg")}
                    title="Tìm kiếm trong vùng"
                    alt="Tìm kiếm trong vùng"
                  />
                </button>
                <button type="button" className={`btn search-by-type-button ${currentDrawMode === GeometryType.CIRCLE && "active"}`}
                  onClick={(evt) => handleClickDrawFilter(evt, GeometryType.CIRCLE)}>
                  <img
                    src={require("../../../assets/icon/circle.svg")}
                    title="Tìm kiếm trong đường tròn"
                    alt="Tìm kiếm trong đường tròn"
                  />
                </button>
                <button type="button" className={`btn search-by-type-button ${currentDrawMode === GeometryType.POINT && "active"}`}
                  onClick={(evt) => handleClickDrawFilter(evt, GeometryType.POINT)}>
                  <img
                    src={require("../../../assets/icon/radius.svg")}
                    title="Tìm kiếm trong phạm vi bán kính"
                    alt="Tìm kiếm trong phạm vi bán kính"
                  />
                </button>
                <button type="button" className={`btn search-by-type-button ${currentDrawMode === "Box" && "active"}`}
                  onClick={(evt) => handleClickDrawFilter(evt, "Box")}>
                  <img
                    src={require("../../../assets/icon/cursor.svg")}
                    title="Tìm kiếm trong hình chữ nhật"
                    alt="Tìm kiếm trong hình chữ nhật"
                  />
                </button>
              </div>
              {
                currentDrawMode === GeometryType.POINT && <Container fluid>
                  <Row>
                    <Col className="pl-0">
                      <Form.Group>
                        <Form.Label>Kinh Độ</Form.Label>
                        <Form.Control type="number" disabled placeholder="Kinh Độ" id="longitudeInput" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Vĩ Độ</Form.Label>
                        <Form.Control type="number" disabled placeholder="Vĩ Độ" id="latitudeInput" />
                      </Form.Group>
                    </Col>
                    <Col className="pr-0">
                      <Form.Group controlId="radiusInput">
                        <Form.Label>Bán kính (m)</Form.Label>
                        <Form.Control type="text" placeholder="Bán kính" min={0} value={radiusValue} onChange={(event) => setRadiusValue(event.target.value)} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              }
              <div className="search-with-attribute d-flex align-items-center mb-2">
                <div
                  className="fa-attribute"
                  onClick={showNotificationNotChooseLayer}
                >
                  <FontAwesomeIcon
                    icon={!isSearchWithAttribute ? faCircle : faCheck}
                    className={
                      !isSearchWithAttribute ? "fa-circle" : "fa-check"
                    }
                  />
                </div>

                <span className="ml-1">Theo thuộc tính</span>
              </div>

              {isSearchWithAttribute && objectSearch && (
                <div className="select-attributes-container pl-1 pr-1">
                  {renderAttributesOfSelectedObject(attributesOfSelectedObject)}
                </div>
              )}
            </div>
          </div>

          <div className="position-absolute footer-button-group w-100">
            <button className="btn btn-primary" type="submit" onClick={() => props.openLayerController.getCurrentDrawFeature()}>
              {" "}
              <img
                src={require("../../../assets/icon/search.svg")}
                alt="Tìm kiếm"
              />{" "}
              Tìm kiếm
            </button>
            <button className="btn btn-dark" type="button" onClick={clearData}>
              <img src={require("../../../assets/icon/delete.svg")} alt="Xóa" />{" "}
              Xóa
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => handleCloseFilterModal()}
            >
              <img
                src={require("../../../assets/icon/closeX.svg")}
                alt="Close"
              />{" "}
              {LanguageCollects.planningMapView.filterInfomationPopup.close}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const getData = (str) => {
  try {
    const _data = JSON.parse(str);
    return _data.data || "";
  } catch (error) {
    return "";
  }
};

const removeAllClass = (data) => {
  $(`#number_equals_${data.col}`).removeClass("active");
  $(`#number_less_${data.col}`).removeClass("active");
  $(`#number_greater_${data.col}`).removeClass("active");
  $(`#number_between_${data.col}`).removeClass("active");
};

function RenderAttributeSelect(props) {
  const { data, features, getValues, setValue, register } = props;

  const contentRender = () => {
    switch (data.type_display) {
      case TYPE_DISPLAY.DROPDOWN: {
        const dataResult = [];
        if (features) {
          features.map((feature) => {
            let _feature;
            if (feature.columnName === data.col) {
              _feature = feature.value
            };
            if (_feature && !dataResult.some((item) => item.value === _feature))
              dataResult.push({
                label: _feature,
                value: _feature,
              });
          });
        }

        return (
          <div>
            <h6>{data.alias}:</h6>
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder=""
              name={`select_${data.col}`}
              options={dataResult}
              onChange={(newValue) =>
                setValue(
                  `${data.col}`,
                  `{"col": "${data.col}", "type": "${TYPE_DISPLAY.DROPDOWN}", "v": "${newValue.value.toLowerCase()}", "op": "="}`
                )
              }
            />
            <input ref={register} name={`${data.col}`} type="hidden" />
          </div>
        );
      }
      case TYPE_DISPLAY.NUMBER: {
        return (
          <div>
            <div className="d-flex align-items-center">
              <h6 className="mb-2">{data.alias}</h6>

              <ul className="oNumber mb-2">
                <li
                  id={`number_equals_${data.col}`}
                  title="Nhập một số"
                  className="active"
                  onClick={() => {
                    removeAllClass(data);
                    $(`#number_equals_${data.col}`).addClass("active");

                    const condition_type = CONDITION_TYPE.BANG;
                    $(`#type_${data.col}`).val(condition_type);
                    setValue(
                      data.col,
                      `{"col": "${data.col}", "type": "${TYPE_DISPLAY.NUMBER
                      }", "v": "${getData(
                        getValues(data.col)
                      )}", "op": "${condition_type}"}`
                    );
                  }}
                >
                  <span v="=" className="operator">
                    =
                  </span>
                </li>
                <li
                  id={`number_less_${data.col}`}
                  title="Nhập một số"
                  onClick={() => {
                    removeAllClass(data);
                    $(`#number_less_${data.col}`).addClass("active");

                    const condition_type = CONDITION_TYPE.NHO_HON;
                    $(`#type_${data.col}`).val(condition_type);
                    setValue(
                      data.col,
                      `{"col": "${data.col}", "type": "${TYPE_DISPLAY.NUMBER
                      }", "v": "${getData(
                        getValues(data.col)
                      )}", "op": "${condition_type}"}`
                    );
                  }}
                >
                  <span v="less" className="operator">
                    &lt;
                  </span>
                </li>
                <li
                  id={`number_greater_${data.col}`}
                  title="Nhập một số"
                  onClick={() => {
                    removeAllClass(data);
                    $(`#number_greater_${data.col}`).addClass("active");

                    const condition_type = CONDITION_TYPE.LON_HON;
                    $(`#type_${data.col}`).val(condition_type);
                    setValue(
                      data.col,
                      `{"col": "${data.col}", "type": "${TYPE_DISPLAY.NUMBER
                      }", "v": "${getData(
                        getValues(data.col)
                      )}", "op": "${condition_type}"}`
                    );
                  }}
                >
                  <span v="greater" className="operator">
                    &gt;
                  </span>
                </li>
                <li
                  id={`number_between_${data.col}`}
                  title="Nhập 2 số theo cấu trúc [a b]"
                  onClick={() => {
                    removeAllClass(data);
                    $(`#number_between_${data.col}`).addClass("active");

                    const condition_type = CONDITION_TYPE.TRONG_KHOANG;
                    $(`#type_${data.col}`).val(condition_type);
                    setValue(
                      data.col,
                      `{"col": "${data.col}", "type": "${TYPE_DISPLAY.NUMBER
                      }", "v": "${getData(
                        getValues(data.col)
                      )}", "op": "${condition_type}"}`
                    );
                  }}
                >
                  <span v="between" className="operator">
                    []
                  </span>
                </li>
              </ul>
            </div>

            <input
              className="form-control"
              name={`${data.col}`}
              type="text"
              min="0"
              step="any"
              placeholder={data.name}
              onChange={(e) => {
                const value = e.target.value;
                if (!value) {
                  setValue(data.col, "");
                } else {
                  const condition_type =
                    $(`#type_${data.col}`).val() || CONDITION_TYPE.BANG;
                  $(`#type_${data.col}`).val(condition_type);
                  setValue(
                    data.col,
                    `{"col": "${data.col}", "type": "${TYPE_DISPLAY.NUMBER}", "v": "${value}", "op": "${condition_type}"}`
                  );
                }
              }}
            />
            <input
              id={`type_${data.col}`}
              name={`type_${data.col}`}
              type="hidden"
            />
            <input ref={register} name={`${data.col}`} type="hidden" />
          </div>
        );
      }
      default:
        return (
          <>
            <h6>{data.alias}</h6>
            <input
              className="form-control"
              name={`value_${data.col}`}
              type="text"
              placeholder={data.name}
              onChange={(e) =>
                setValue(
                  `${data.col}`,
                  `{"col": "${data.col}", "type": "${TYPE_DISPLAY.CHAR}", "v": "${e.target.value}", "op": "LIKE"}`
                )
              }
            />
            <input ref={register} name={`${data.col}`} type="hidden" />
          </>
        );
    }
  };

  return <div className="mb-2">{contentRender()}</div>;
}

const mapStateToProps = (state) => ({
  isDisplay: state.mapToolsPanel.displayInfomationSearch,
  map: state.mapData.map,
  openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SetDisplayInfomationPopup: MapToolsPanelsStore.SetDisplayInfomationPopup,
      SaveDataFilter: MapDataStore.SaveDataFilter,
      SaveInfomationForTable: MapDataStore.SaveInformationForTable,
      SaveHeaderForTable: MapDataStore.SaveHeaderForTable,
      SaveOpenlayerFeaturesSelected: OpenlayerStore.SaveOpenlayerFeaturesSelected,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderFilterInformationPopup);
